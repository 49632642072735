import React, { Component } from 'react'
import Mapa from '../components/googleMaps'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {FaRegEnvelope} from 'react-icons/fa'
import {FaPhone} from 'react-icons/fa'
import {FaSkype} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'
import Titulo from '../components/Titulo'
import Translator from '../components/Translator';


import emailjs from 'emailjs-com';
import config from '../Email2';

const $ = window.$;

export default class PaginaContactos extends Component {

    state={
        send: false,
    }

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(config.SERVICE_ID, config.TEMPLATE_ID, e.target, config.USER_ID)
            .then((result) => {
                document.getElementById("mailForm").reset();
                this.state = { send : true}
                $('#modalContactos').modal('toggle');
            }, (error) => {
                console.log(error.text);
            });
    }

    handleClick = () =>{
        this.state = ({ send : false})
    }


    render() {
        
        const Titulo2 = <Translator name="contactosTitulo"/>;
        const Subtitulo = <Translator name="contactosSubtitulo"/>;

        const LeftTitulo = <Translator name="contactosLeftTitulo"/>;
        const LeftSubtitulo = <Translator name="contactosLeftSubtitulo"/>;
        const LeftTituloRedes = <Translator name="contactosLeftTituloRedes"/>;

        const RightTitulo = <Translator name="contactosRightTitulo"/>;
        const RightNome = <Translator name="contactosRightNome"/>;
        const RightEmail =  <Translator name="contactosRightEmail"/>;
        const RightMessagem = <Translator name="contactosRightMensagem"/>;
        const RightFormBTN = <Translator name="contactosRightFormBTN"/>;

        const BTN = <Translator name="contactosBTN"/>;
        
    
        return (
            <>
            <Mapa />
                    <div className="contactopf">
                        <div className="contactopfa">
                            <span>
                            <h1>{Titulo2}</h1>
                            <h3>{Subtitulo}</h3>
                            <h2>+351 919 245 867</h2>
                            </span>
                        </div>
                    </div>    
                    <div className="apblockC">
                        <div className ="wrapper-apC">
                            <article className="img-infoapC">
                            <span className="mainSpanContact">
                                <span className="mediumSpanContact">
                                <Titulo title={LeftTitulo} subtitle={LeftSubtitulo}/>
                                <p><FaMapMarkerAlt/>  Rua Branco Martins, 24A</p>
                                <p><FaPhone/> +351 919 245 867</p>
                                <a href="mailto:reservas@alojamentobatata.com" style={{fontSize: "15px",color: "#353535"}}><FaRegEnvelope/> reservas@alojamentobatata.com</a>
                                </span>
                                <span className="mediumSpanContact2">
                                <h4>{LeftTituloRedes}</h4>
                                <span><a href="skype:aurelio.cavaleiro?call"><FaSkype/></a></span>
                                <span><a href="https://www.facebook.com/alojamentobatata"><FaFacebookF/></a></span>
                                <span><a href="https://www.linkedin.com/in/alojamento-batata-868a48144/"><FaLinkedinIn/></a></span>
                                </span>
                            </span>
                            </article>
                            <article className="form-infoapC">
                                <h2>{RightTitulo}</h2>
                                <div className="bootstrap-iso">
                                <div className="container-fluid" style={{paddingLeft: '0px'}}>
                                <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12" style={{flex: 'none', maxWidth: '100%', paddingRight: '70px', width: '100%'}}>
                                    <form onSubmit={this.sendEmail} id="mailForm">
                                    <div className="form-group ">
                                         
                                    { localStorage.lang === 'en' && <input className="form-control" id="inputNome" name="nome" placeholder="Name" type="text" style={{borderRadius:"0", fontStyle: "italic", height: "calc(1.5em + .75rem + 14px)", trasition: "none !important"}} required/>}
                                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <input className="form-control" id="inputNome" name="nome" placeholder="Nome" type="text" style={{borderRadius:"0", fontStyle: "italic", height: "calc(1.5em + .75rem + 14px)", trasition: "none !important"}} required/>}
                                    { localStorage.lang === 'es' && <input className="form-control" id="inputNome" name="nome" placeholder="Nome" type="text" style={{borderRadius:"0", fontStyle: "italic", height: "calc(1.5em + .75rem + 14px)", trasition: "none !important"}} required/>}
                                    { localStorage.lang === 'fr' && <input className="form-control" id="inputNome" name="nome" placeholder="Nom" type="text" style={{borderRadius:"0", fontStyle: "italic", height: "calc(1.5em + .75rem + 14px)", trasition: "none !important"}} required/>} 
                                    
                                    </div>
                                    <div className="form-group ">
                                    <input className="form-control" id="inputEmail" name="email" type="text" placeholder="Email" style={{borderRadius:"0", fontStyle: "italic", height: "calc(1.5em + .75rem + 14px)"}} required/>
                                    </div>
                                    <div className="form-group ">
                                    { localStorage.lang === 'en' && <textarea className="form-control" id="inputMensagem" name="mensagem" placeholder="Message" rows="5" style={{borderRadius:"0", fontStyle: "italic"}} required></textarea>}
                                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <textarea className="form-control" id="inputMensagem" name="mensagem" placeholder="Mensagem" rows="5" style={{borderRadius:"0", fontStyle: "italic"}} required></textarea>}
                                    { localStorage.lang === 'es' && <textarea className="form-control" id="inputMensagem" name="mensagem" placeholder="Mensagem" rows="5" style={{borderRadius:"0", fontStyle: "italic"}} required></textarea>}
                                    { localStorage.lang === 'fr' && <textarea className="form-control" id="inputMensagem" name="mensagem" placeholder="Message" rows="5" style={{borderRadius:"0", fontStyle: "italic"}} required></textarea>} 
                                    
                                    </div>
                                    <div className="form-group">
                                    <div className="btnenviarv2">
                                    <button name="submit" type="submit" value="Send" onClick={this.handleClick}> {RightFormBTN} </button>
                                    </div>
                                    </div>
                                    <div class="modal fade" id="modalContactos" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{height: "auto"}}>
                                            <div class="modal-dialog cascading-modal" role="document" style={{height: "auto"}}>
                                                <div class="modal-content" style={{height: "auto"}}>   
                                                    <div class="modal-header light-blue darken-3 white-text" style={{height: "auto"}} >
                                                        <h4 class="title"><i class="fas fa-users"></i></h4>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>                 
                                                    <div class="modal-body mb-0 text-center" style={{height: "auto"}}>                           
                                                        <h2 style={{color:"black",fontSize:"15px",paddingTop:"0px",paddingLeft:"0px"}}><Translator name="modalFormSubmitContactos"/></h2>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    </form>
                                </div>
                                </div>
                                </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="alojamentopf">
                        <div className="alojementopfa">
                            <span>
                            <a id="alojamentoa" href="./reservar">{BTN}</a>
                            </span>
                        </div>
                    </div>
            </>
        )
    }
}