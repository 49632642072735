import React from 'react';
import './App.css';
import './reset.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';
import 'react-day-picker/lib/style.css';
import 'react-sharingbuttons/dist/main.css'



import Home from './components/PaginaHome';
import Alojamento from './pages/Alojamento';
import Apartamentos from './pages/Apartamentos';
import ApartamentosSEP from './pages/ApartamentosSEP'
import QuartosSEP from './pages/QuartosSEP'
// ----- Os apartamentosSEP vão servir para mostrar cada apartamentos automaticamente quando este é selecionado ----- //
import Ofertas from './pages/Ofertas';
import Nazare from './pages/Nazare';
import Contactos from './pages/Contactos';
import Reservar from './pages/Reservar';
import Error from './pages/Error';
import Footer from './components/Footer'
import Formulario from "./components/Formulario"

import MyProvider from '../src/context/providerlang'

// ---- importar para por as rotas funcionais ----- \\
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

// ---- EXACT PATH -> Para dar render separado! ----\
// ---- :slug ou :slugv2-> Variavel que permite mostrar os quartos individuais/ofertas individuais quando selecionados ----\
// ---- coloca-se o switch para que quando for introdzido um url que não é compativel com nenhuma das rotas definidas este rencaminha o utilizador para uma pagina de erro! ----\

import Navbar from "./components/Navbar"

function App() {
  return (
    <>
    <Router>
    <MyProvider>
    <Navbar />
    <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/alojamentos" component={Alojamento}/>
        <Route exact path="/apartamentos" component={Apartamentos}/>
        <Route exact path="/apartamentos/:slug" component={ApartamentosSEP}/>
        <Route exact path="/ofertas" component={Ofertas}/>
        <Route exact path="/ofertas/:slug" component={Ofertas}/>
        <Route exact path="/quartos/:slug" component={QuartosSEP}/>
        <Route exact path="/nazare" component={Nazare}/>
        <Route exact path="/contactos" component={Contactos}/>
        <Route exact path="/reservar" component={Reservar}/>
        <Route path="/reservare" component={Formulario}/>
        <Route component={Error}/>
    </Switch>
    <Footer/>
    </MyProvider>
    </Router>
    </>
  );
}

export default App;
