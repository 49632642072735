import React, { Component } from 'react'
import { QuartosContext } from '../context'
import { Link } from 'react-router-dom'
import StyledHero from '../components/StyledHero'
import {FaHome} from 'react-icons/fa'
import {FaUserAlt} from 'react-icons/fa'
import {FaBed} from 'react-icons/fa'
import {FaShower} from 'react-icons/fa'
import {FaCamera} from 'react-icons/fa'
import {FaShareAlt} from 'react-icons/fa'
import {FaCheckCircle} from 'react-icons/fa'
import Titulo from '../components/Titulo'
import TituloC from '../components/TituloCentro'
import Slider from "react-slick";
import {FaComments} from 'react-icons/fa'
import Lightbox from 'react-image-lightbox';
import Translator from '../components/Translator';
import { Facebook, Email } from 'react-sharingbuttons'
const $ = window.$;

export default class QuartosSEP extends Component {
    constructor(props){
        super(props);
        this.state={
            slug: this.props.match.params.slug,
            photoIndex: 0,
            isOpen: false,
            slugform: undefined
        };
    }


    handleSlug = (e) => {
        this.setState({ slugform: e.target.value });
    }

    handleCheckAvailabilty = () => {
        this.props.history.push('/reservare', {
            myProp: this.state,
        })
    }

    componentDidMount() {
        $('#mapTop').height("0");
      }

static contextType = QuartosContext;
    //componentDidMount(){}
    render() {  
            
            const { getQuarto } = this.context;
            const quarto = getQuarto(this.state.slug);
            const { photoIndex, isOpen , slugform } = this.state;
        
            const DescricaoTit = <Translator name="apartamentoDescricaoTitulo"/>;
            const DescricaoSub = <Translator name="apartamentoDescricaoSubtitulo"/>;

            const ComodidadeTit = <Translator name="apartamentoComodidadeTitulo"/>;
            const ComodidadeSub = <Translator name="apartamentoComodidadeSubtitulo"/>;

            const InformacaoTit = <Translator name="apartamentoInformacaoTitulo"/>;
            const InformacaoSub = <Translator name="apartamentoInformacaoSubtitulo"/>;

            const ComentariosTit = <Translator name="apartamentoComentarioTitulo"/>;

            const VoltaNazareTit = <Translator name="apartamentoVoltaNazareTitulo"/>;
            const VoltaNazareSub = <Translator name="apartamentoVoltaNazareSubtitulo"/>;

            const ApartSimiliaresTit = <Translator name="apartamentoApartSimiliaresTitulo"/>;
            const ApartSimiliaresSub = <Translator name="apartamentoApartSimiliaresSubtitulo"/>;

            const BTNRSVTit = <Translator name="apartamentoBTNTexto"/>;
            const BTNRSVSub = <Translator name="apartamentoBTNTextuBTN"/>;
            const BTNFotos = <Translator name="apartamentoBTNFotos"/>;
            const BTNPartilhe = <Translator name="apartamentoBTNPartilhar"/>;
        
        if(!quarto){
            return( 
                <div className="error">
                <h3>Não existe quarto correspondente a sua pesquisa</h3>
                <Link to='/apartamentos' className="btn-primary">
                    Regressar
                </Link>
                </div>
                );
        }
        const{nomeq,descricao,capacidade,tamanho,casasdebanho,camas,comodidades,informacao,fotoBanner,quartoSimiliar,comentarios,fotosSlides,nazareVisitar,multiTexto,multiTexto2,multiTexto3} = quarto;

        this.state.slugform = nomeq;
        document.title= nomeq

        
        const images = fotosSlides.map(item =>{
            return item.fields.file.url
        })

        const url = window.location.href;
        const subject= "Alojamento Batata" + " - " + nomeq;
        
        
        return (
        <>
        <StyledHero img ={fotoBanner.fields.file.url}  onClick={() => this.setState({ isOpen: true })}></StyledHero>
        <div className="container-div">
            <div className="centerblock">
                <div className="leftd"> 
                <button className="partilhar" data-toggle="modal" data-target="#modalSocial"><FaShareAlt/>{BTNPartilhe}</button>
                </div>
                <div class="modal fade" id="modalSocial" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{height: "auto"}}>
                    <div class="modal-dialog cascading-modal" role="document" style={{height: "auto"}}>
                        <div class="modal-content" style={{height: "auto"}}>   
                        <div class="modal-header light-blue darken-3 white-text" style={{height: "auto"}} >
                            <h4 class="title"><i class="fas fa-users"></i> {BTNPartilhe}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                        </div>                 
                        <div class="modal-body mb-0 text-center" style={{height: "auto"}}>                           
                        <Facebook url={url}/>
                        <Email subject={subject} url={url}/>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="centerd"> 
                <h2>{nomeq}</h2>
                </div>
                <div className="rightd">
                    <button className="verfotos" type="button" onClick={() => this.setState({ isOpen: true })}><FaCamera/>{BTNFotos}</button>
                    {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                    )}
                </div>
            </div>
            <span className="splitRoom">
            <div className="leftda" style={{width: "50%"}}> 
                    <button className="partilhar" data-toggle="modal" data-target="#modalSocial"><FaShareAlt/>{BTNPartilhe}</button>
            </div>
            <div className="rightda" style={{width: "50%"}}>
                    <button className="verfotos" type="button" onClick={() => this.setState({ isOpen: true })}><FaCamera/>{BTNFotos}</button>

                    {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                    )}
            </div>
            </span>
        </div>
        <section className="single-room-div">
            <div className="single-room-container">
                <div className="single-room-infotl">
                        <span><i><FaHome/></i><h3>{tamanho}m²</h3></span> 
                        <span><i><FaUserAlt/></i>
                        { localStorage.lang === 'en' && <h3> {multiTexto.en.adultos} </h3>}
                        { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h3> {multiTexto.pt.adultos} </h3>}
                        { localStorage.lang === 'es' && <h3> {multiTexto.es.adultos} </h3>}
                        { localStorage.lang === 'fr' && <h3> {multiTexto.fr.adultos} </h3>} 
                        </span>
                        <span><i><FaBed/></i>
                        { localStorage.lang === 'en' && <h3> {multiTexto2.en.camas} </h3>}
                        { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h3> {multiTexto2.pt.camas} </h3>}
                        { localStorage.lang === 'es' && <h3> {multiTexto2.es.camas} </h3>}
                        { localStorage.lang === 'fr' && <h3> {multiTexto2.fr.camas} </h3>} 
                        </span>
                        <span><i><FaShower/></i> <h3>{casasdebanho}</h3></span>
                    </div>
                <div className="single-room-description">
                    <Titulo title={DescricaoTit} subtitle={DescricaoSub} />
                    { localStorage.lang === 'en' && <p> {multiTexto3.en.descricao} </p>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {multiTexto3.pt.descricao} </p>}
                    { localStorage.lang === 'es' && <p> {multiTexto3.es.descricao} </p>}
                    { localStorage.lang === 'fr' && <p> {multiTexto3.fr.descricao} </p>} 
                </div>
            </div>
        </section>
        <section className="servicesA">
                <div className="services-title">
                <Titulo title={ComodidadeTit} subtitle={ComodidadeSub}/>
                </div>
                <div className="services-center">
                    {comodidades.map((item,index)=>{
                        return <article key={index} className="service">
                            <div className="leftS">
                            <i><img src={item.fields.imgComodidade.fields.file.url} alt="img2"></img></i>
                            </div>
                            <div className="rightS">
                            { localStorage.lang === 'en' && <h5> {item.fields.multiTexto.en} </h5>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h5> {item.fields.multiTexto.pt} </h5>}
                            { localStorage.lang === 'es' && <h5> {item.fields.multiTexto.es} </h5>}
                            { localStorage.lang === 'fr' && <h5> {item.fields.multiTexto.fr} </h5>} 
                            </div>
                            </article>
                    })}
                </div>
        </section>
        <section className="services">
                <div className="services-title">
                <Titulo title={InformacaoTit} subtitle={InformacaoSub}/>
                </div>
                <div className="services-center">
                {informacao.map((item,index)=>{
                        return <article key={index} className="service">
                            <div className="leftSN">
                            <FaCheckCircle/>
                            </div>
                            <div className="rightSNN">
                            { localStorage.lang === 'en' && <h6> {item.fields.multiTexto.en.titulo} </h6>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h6> {item.fields.multiTexto.pt.titulo} </h6>}
                            { localStorage.lang === 'es' && <h6> {item.fields.multiTexto.es.titulo} </h6>}
                            { localStorage.lang === 'fr' && <h6> {item.fields.multiTexto.fr.titulo} </h6>} 


                            { localStorage.lang === 'en' && <p> {item.fields.multiTexto2.en.subtitulo} </p>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {item.fields.multiTexto2.pt.subtitulo} </p>}
                            { localStorage.lang === 'es' && <p> {item.fields.multiTexto2.es.subtitulo} </p>}
                            { localStorage.lang === 'fr' && <p> {item.fields.multiTexto2.fr.subtitulo} </p>} 
                            </div>
                            </article>
                    })}
                </div>
        </section>
        { comentarios !== undefined &&
        <div className="blockS">
                <div className="featured-quotes">
                    <TituloC title="O que os nossos clientes dizem"/>
                    <div className="wrapperS">
                        <Slider
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            infinite={true}
                            dots={true}
                        >
                        {comentarios.map((item,index)=>{
                            return <div key={index} className="PageS">
                            <i><FaComments/></i>
                            { localStorage.lang === 'en' && <h5> {item.fields.multiTexto.en.comentario} </h5>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h5> {item.fields.multiTexto.pt.comentario} </h5>}
                            { localStorage.lang === 'es' && <h5> {item.fields.multiTexto.es.comentario} </h5>}
                            { localStorage.lang === 'fr' && <h5> {item.fields.multiTexto.fr.comentario} </h5>}
                            <p>{item.fields.site}</p>
                        </div>
                        })}
                        </Slider>
                    </div>
                </div>
        </div>
        }
        <div className="rsvcontainer">
            <div className="centerblockrsv">
                <div className="rsvleft">
                    <h2>{BTNRSVTit}</h2>
                </div>
                <div className="rsvright">
                    <button className="rsvq" value="submit" onClick={this.handleCheckAvailabilty}>{BTNRSVSub}</button>
                </div>
            </div>
        </div>
        <section className="servicesN">
                <div className="services-title">
                <Titulo title={VoltaNazareTit} subtitle={VoltaNazareSub}/>
                </div>
                <div className="services-center">
                {nazareVisitar.map((item,index)=>{
                        return <article key={index} className="service">
                            <div className="leftSNA">
                            <img src={item.fields.imgServico.fields.file.url} alt="img2"></img>
                            </div>
                            <div className="rightSNA">
                            { localStorage.lang === 'en' && <h6> {item.fields.multiTexto.en.titulo} </h6>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h6> {item.fields.multiTexto.pt.titulo} </h6>}
                            { localStorage.lang === 'es' && <h6> {item.fields.multiTexto.es.titulo} </h6>}
                            { localStorage.lang === 'fr' && <h6> {item.fields.multiTexto.fr.titulo} </h6>} 

                            { localStorage.lang === 'en' && <p> {item.fields.multiTexto2.en.distancia} </p>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {item.fields.multiTexto2.pt.distancia} </p>}
                            { localStorage.lang === 'es' && <p> {item.fields.multiTexto2.es.distancia} </p>}
                            { localStorage.lang === 'fr' && <p> {item.fields.multiTexto2.fr.distancia} </p>} 

                            { localStorage.lang === 'en' && <p> {item.fields.multiTexto3.en.tempo} </p>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {item.fields.multiTexto3.pt.tempo} </p>}
                            { localStorage.lang === 'es' && <p> {item.fields.multiTexto3.es.tempo} </p>}
                            { localStorage.lang === 'fr' && <p> {item.fields.multiTexto3.fr.tempo} </p>} 
                            </div>
                            </article>
                    })}
                </div>
            </section>
        <section className="featured-rooms">
            <div className="services-title">
                <Titulo title={ApartSimiliaresTit} subtitle={ApartSimiliaresSub}/>
            </div>
            <div className="services-center">
            {quartoSimiliar.map((item,index)=>{
                return  <article key={index} className="roomS" onClick={() =>{window.location = `/quartos/${item.fields.slug}`}}>
                <div className="img-container">
                    <img src={item.fields.fotoBanner.fields.file.url} alt="img3"/>
                </div>
                <div className="room-info">
                    <div className="room-left">
                        <h3>{item.fields.nomeq}</h3>
                        <div className="room-left-wrap">
                        <i><FaUserAlt/></i>
                        { localStorage.lang === 'en' && <h3> {item.fields.multiTexto.en.adultos} </h3>}
                        { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h3> {item.fields.multiTexto.pt.adultos} </h3>}
                        { localStorage.lang === 'es' && <h3> {item.fields.multiTexto.es.adultos} </h3>}
                        { localStorage.lang === 'fr' && <h3> {item.fields.multiTexto.fr.adultos} </h3>}
                        </div>
                        </div>
                    <div className="room-right">
                <div className="room-price">
                    {item.fields.preco}€
                </div>
                <p><Translator name="homeApartamentosContainerPreço"/></p>
                </div>
                </div>
            </article>
            })}
            </div>
        </section>
        </>
        );
    }
}
