import React from 'react'

export default function TituloTexto({title, subtitle}) {
    return (
        <div className="row" style={{textAlign: "left"}}>
        <div className="col-xs-12">
            <div className="page-header" style={{display:"inline-block",margin:"20px", marginBottom:"0px", marginLeft:"13px"}}>
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </div>
        </div>
        </div>
    )
}
