import React, { Component } from 'react'
import Pagina from '../components/PaginaContactos'
const $ = window.$;

class Contactos extends Component {
    componentDidMount(){
        document.title = 'Contactos';
        $('#mapTop').height("0");
          
    }
    render() {
        return (
            <Pagina/>
        )
    }
}
export default Contactos

