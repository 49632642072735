import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {FaUserAlt} from 'react-icons/fa'
import Translator from '../components/Translator';

export default function Quarto( { Quarto } ) {
    const{nomeq, slug, fotoBanner, preco, multiTexto} = Quarto;
    return (
        <article className="room" onClick={() =>{window.location = `/quartos/${slug}`}}>
            <div className="img-container">
                <img src={fotoBanner.fields.file.url} alt="single room" />
                <Link to={`/quartos/${slug}`} className="btn-primary room-link">Saber Mais</Link> 
            </div>
            <div className="room-info">
                <div className="room-left">
                    <h2>{nomeq}</h2>
                    <div className="room-left-wrap">
                    <i><FaUserAlt/></i><span>
                        { localStorage.lang === 'en' && <h3> {multiTexto.en.adultos} </h3>}
                        { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h3> {multiTexto.pt.adultos} </h3>}
                        { localStorage.lang === 'es' && <h3> {multiTexto.es.adultos} </h3>}
                        { localStorage.lang === 'fr' && <h3> {multiTexto.fr.adultos} </h3>}
                    </span>
                    </div>
                    </div>
                <div className="room-right">
            <div className="room-price">
                {preco}€
            </div>
            <p><Translator name="homeApartamentosContainerPreço"/></p>
            </div>
            </div>
        </article>
    );
}

Quarto.propTypes = {
    Quarto:PropTypes.shape({
        nomeq:PropTypes.string.isRequired,
        slug:PropTypes.string.isRequired,
        preco:PropTypes.number.isRequired
    })
}
