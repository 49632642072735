import React from 'react'
import Quarto from './Quarto'
import Translator from '../components/Translator';

export default function QuartosList({Quartos}) {
    
    return (

        <div className="container">
            <div className="row" style={{textAlign: "center"}}>
                <div className="col-xs-12">
                    <div className="page-header" style={{display: "inline-block"}}>
                        <h1><Translator name="quartosContainerTitulo"/></h1><p><Translator name="quartosContainerSubtitulo"/></p>
                        </div>
                </div>
            </div>
            <div className="row rooms-list-text" style={{textAlign: "center"}}>
                <div className="col-xs-12">
                    <p><Translator name="quartosContainerParagrafo"/></p>
                    </div>
            </div>
        <section className="roomslist">
            <div className="roomslist-center">
                {Quartos.map(item =>{
                        return <Quarto key={item.id} Quarto={item} />
                    })
                }
            </div>
        </section>
        </div>
    )
}
