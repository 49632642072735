import React, { Component } from 'react'
import Client from '../Contentful'
import Titulo from '../components/Titulo'
import {FaCheckCircle} from 'react-icons/fa'
import Translator from '../components/Translator';

export default class ServicosNazare extends Component {
    state={
        Services: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "servicosNazare2"
            });
            let Services = this.formatData(response.items);
            this.setState({
                Services,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let Services = {...item.fields}
            return Services;
            });
        return tempItems2;
    }

    render(){

        
        const ComoChegarTit = <Translator name="nazareComoChegarTitulo"/>;
        const ComoChegarSub = <Translator name="nazareComoChegarSubtitulo"/>;

        return(
            <>
            <section className="services">
                <div className="services-title">
                <Titulo title={ComoChegarTit} subtitle={ComoChegarSub}/>
                </div>
                <div className="services-center">
                    {this.state.Services.map((item,index)=>{
                        return <article key={index} className="service">
                            <div className="leftSN">
                            <FaCheckCircle/>
                            </div>
                            <div className="rightSNN">
                            { localStorage.lang === 'en' && <h6> {item.multiTexto.en.nome} </h6>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h6> {item.multiTexto.pt.nome} </h6>}
                            { localStorage.lang === 'es' && <h6> {item.multiTexto.es.nome} </h6>}
                            { localStorage.lang === 'fr' && <h6> {item.multiTexto.fr.nome} </h6>} 

                            { localStorage.lang === 'en' && <p> {item.multiTexto2.en.quilometros} </p>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {item.multiTexto2.pt.quilometros} </p>}
                            { localStorage.lang === 'es' && <p> {item.multiTexto2.es.quilometros} </p>}
                            { localStorage.lang === 'fr' && <p> {item.multiTexto2.fr.quilometros} </p>} 
                            </div>
                            </article>
                    })}
                </div>
            </section>
            </>
        )
    }
}