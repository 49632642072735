import React, { Component } from 'react'
import Form from '../components/Formulario2'
import Titulo from '../components/Titulo'
import Translator from '../components/Translator';

export default class PaginaReservar extends Component {
    render() {
        const Title =  <Translator name="formBottomTitle"/>;
        const Subtitle = <Translator name="formBottomSubtitle"/>;
        const Text = <Translator name="formBottomText"/>;
        return (
            <>
            <Form />
            <div className="apblockR">
                <div className ="wrapper-apN">
                    <Titulo title={Title} subtitle={Subtitle}/>
                    <p>{Text}</p>
                </div>
            </div>    
        </>
        )
    }
}
