/**
 * You need to create main key values for each text
 */
export default  {
    lang:{
        pt: { //Portugues  
            liAlojamento: 'Alojamento',
            liApartamentos: 'Apartamentos',
            liOfertas: 'Ofertas',
            liNazaré: 'Nazaré',
            liContactos: 'Contactos',
            btnReservarNav: 'RESERVAR',
            //-----------------------------//
            containerRSV: 'Reservar Já',
            containerRSV2: 'Melhor preco garantido',
            containerRSV3: 'Hospedes',
            containerRSVbtn: 'Ver Disponibilidade',
            //-----------------------------//
            homeContainerTitulo:'Alojamento Batata',
            homeContainerSubtitulo:'Um alojamento com os olhos no mar',
            homeContainer: 'O nosso alojamento está situado na Costa de Prata, Nazaré, uma vila piscatória com muita história, e muitas tradições. No total são 8 Apartamento e 4 quartos, localizados num edificio junto ao mar.',
            homeContainer2:'Um alojamento que se diferencia pela qualidade, e serviço prestado a todos os hóspedes durante a sua estadia. Preocupamo-nos com o detalhe de modo a poder proporcionar uma mágnifica estadia.',
            //----------------------------//
            homeApartamentosContainerTitulo:'Explore o Alojamento',
            homeApartamentosContainerPreço:'desde',
            //-----------------------------//
            homeComentariosContainerTitulo:'O que os nossos clientes dizem',
            //-----------------------------//
            homeOfertasContainerTitulo:'Ofertas Especiais',
            //-----------------------------//
            apartamentosContainerTitulo:'Os nossos Apartamentos',
            apartamentosContainerSubtitulo:'Encontre a solução ideal para si',
            apartamentosContainerParagrafo:'No nosso alojamento pode encontrar várias tipologias de Apartamentos. Temos apartamentos com capacidade de 1 a 7 pessoas.',
            apartamentosContainerParagrafo2:'Os apartamentos estão divididos por 3 andares, sem elevador.',
            quartosContainerTitulo:'Os nossos Quartos',
            quartosContainerSubtitulo:'Encontre a solução ideal para si',
            quartosContainerParagrafo:'No nosso alojamento pode encontrar vários tipos de Quartos. Temos quartos com e sem vista para o mar.',
            //-----------------------------//
            footerNewsletter:'A nossa newsletter',
            footerNewsletterBTN:'Subscrever',
            footerNewsletterPlaceHolder:'Inserir o seu email',
            //-----------------------------//
            alojamentoContainerTitulo:'Alojamento Batata',
            alojamentoContainerSubtitulo:'Com os olhos no mar',
            alojamentoContainerParagrafo:'O nosso alojamento está situado na Avenida Manuel Remígio (junto ao mar). É composto por 8 apartamentos e 4 quartos.',
            alojamentoContainerParagrafov2:'2 Apartamentos e 2 quartos com vista para o mar, 6 apartamentos e 2 quartos sem vista para o mar. Todos os apartamentos contam com uma decoração elegante, moderna e arrojada. Estão equipados com eletrodomésticos necessários para uma excelente estadia, WI-FI, lençois, e produtos de higiene pessoal. Contamos com uma receção no alojamento, onde oferecemos apoio aos nossos hóspedes, diferenciando-nos assim, da maioria dos alojamentos na Nazaré.',
            alojamentoContainerBTNReservar:'RESERVE AGORA!',
            //-----------------------------//
            FormTitleV1:'Preencha os campos',
            FormTitleV2:'Pedido de informações',
            FormSubtitle:'Dados Pessoais',
            FormApartamentoTitle:'Escolher O Apartamento',
            formApartamentoPlaceholder:'Escolha o seu apartamento',
            formOfertaTitle:'Ofertas Especiais',
            formOfertaPlaceholder:'Escolha uma opção',
            formNomePlaceholder:'Nome',
            formEmailPlaceholder:'Email',
            formTelefonePlaceholder:'Telefone',
            formMensagemPlaceholder:'Mensagem',
            formRSVPlaceholder:'Pedir Informações',
            formBottomTitle:'Informações',
            formBottomSubtitle:'A ter em conta.',
            formBottomText:'Não temos serviço de pagamento online. Após o pedido de reserva, irá ser contactado pelo alojamento para proceder à conclusão da mesma. A sua reserva só estará concluida quando lhe enviarmos um mail com a informação da mesma. Não perca tempo!',
            formAdultosTitle:'Nº De',
            //-----------------------------//
            ServicosTitulo:'Serviços Incluídos',
            ServicosSubtitulo:'Temos ao seu dispor',
            Servicosv2Titulo:'Serviços Extra',
            Servicosv2Subtitulo:'Mediante uma marcação',
            //-----------------------------//
            ofertaListTitulo:'Ofertas Especiais',
            ofertaListSubtitulo:'Aproveite as nossas ofertas',
            opcao1ofertas:'opção 1',
            opcao2ofertas:'opção 2',
            ofertaBTN:'Saber Mais',
            ofertaRibbon:'Desde',
            ofertaRSVBTN:'Reservar',
            ofertaRSVBTN2:'Aproveite Esta Oferta!',
            ofertaCLSBTN:'Fechar',
            //-----------------------------//
            apartamentoDescricaoTitulo:'Descricao',
            apartamentoDescricaoSubtitulo:'Detalhes da nossa propriedade',
            apartamentoComodidadeTitulo:'Comodidades',
            apartamentoComodidadeSubtitulo:'Temos ao seu dispor',
            apartamentoInformacaoTitulo:'Informação Util',
            apartamentoInformacaoSubtitulo:'A saber...',
            apartamentoComentarioTitulo:'O que os nossos clientes dizem',
            apartamentoVoltaNazareTitulo:'À volta da Nazaré',
            apartamentoVoltaNazareSubtitulo:'A nossa sugestão',
            apartamentoApartSimiliaresTitulo:'Apartamentos Similiares',
            apartamentoApartSimiliaresSubtitulo:'Conheça todos os nossos apartamentos',
            apartamentoBTNTexto:'Reserve este apartamento',
            apartamentoBTNTextuBTN:'VER DISPONIBILIDADE',
            apartamentoBTNFotos:'Ver Fotos',
            apartamentoBTNPartilhar:'Partilhe',
            //-----------------------------//
            nazareTituloSub:'Uma vila cheia de história',
            nazareDesc1:'A Nazaré é uma vila portuguesa situada na Costa de Prata, com cerca de 10 300 habitantes. Visitada anualmente por milhares de turistas nacionais e estrangeiros, a Nazaré é hoje uma referência mundial do SURF. Uma magnifica praia, com um promontório que entra pelo mar, faz desta praia uma das 10 mais belas de Portugal.',
            nazareDesc2:'Para os amantes da gastronomia, a Nazaré conta com excelentes restaurantes de peixe fresco e marisco, disponibilizando assim diferentes variedades de pratos, tais como, massa de peixe, sardinhas assadas, cherne e robalo grelhados e ao delicioso marisco.',
            nazareVisitarTitulo:'O que visitar',
            nazareVisitarSubtitulo:'A nossa sugestão',
            nazareComoChegarTitulo:'Como chegar à Nazaré',
            nazareComoChegarSubtitulo:'Boa viagem!',
            nazareSurfText:'A Nazaré é hoje conhecida mundialmente, devido a imagens como esta. Ondas grandes, perfeitas e incríveis, fazem da Praia do Norte uma referência mundial para o surf.',
            nazareBTNTexto:'Venha visitar a Nazaré',
            nazareBTNTextuBTN:'VER DISPONIBILIDADE',
            //-------------------------------------//
            contactosTitulo:'Tem alguma Questão?',
            contactosSubtitulo:'Esclarecemos as suas dúvidas',
            contactosLeftTitulo:'Os nossos contactos',
            contactosLeftSubtitulo:'Estamos à sua espera!',
            contactosLeftTituloRedes:'Redes Sociais',
            contactosRightTitulo:'Mensagem',
            contactosRightNome:'Nome',
            contactosRightEmail:'Email',
            contactosRightMensagem:'Mensagem',
            contactosRightFormBTN:'Enviar',
            contactosBTN:'RESERVE AGORA!',
            //-------------------------------------//
            modalFormSubmitFormulario:'O seu pedido de informações foi enviado. Seremos breves na resposta. Obrigada',
            modalFormSubmitContactos:'Obrigada pelo seu contacto. Seremos breves na resposta',
            modalFormSubmitNewsletter:'Obrigado por subscrever o nosso newsletter',

            
        },
        fr:{ //Francês
            liAlojamento: 'Hébergement',
            liApartamentos: 'Appartements',
            liOfertas: 'Offres',
            liNazaré: 'Nazaré',
            liContactos: 'Contacts',
            btnReservarNav: 'LIVRE',
            //-----------------------------//
            containerRSV: 'Réservez dès maintenant',
            containerRSV2: 'Garantie du meilleur prix',
            containerRSV3: 'Invités',
            containerRSVbtn: 'Vérifier la disponibilité',
            //-----------------------------//
            homeContainerTitulo:'Hébergement Batata',
            homeContainerSubtitulo:'Un hébergement avec les yeux sur la mer.',
            homeContainer: 'Notre logement est situé dans la Côte dArgent, Nazaré, un village de pêcheurs avec beaucoup, dhistoire et de traditions. Au total il y a 8 appartements et 4 chambres situées dans un bâtiment près de la mer. Un hébergement qui se distingue par la qualité et le service à tous les clients pendant leur séjour. Nous nous soucions les détails de façon à offrir un séjour mémorable.',
            //-----------------------------//
            homeApartamentosContainerTitulo:'Explorez le logement',
            homeApartamentosContainerPreço:'de',
            //-----------------------------//
            homeComentariosContainerTitulo:'Ce que nos clients disent',
            //-----------------------------//
            homeOfertasContainerTitulo:'Offres spéciales',
            //-----------------------------//
            apartamentosContainerTitulo:'Nos Appartements',
            apartamentosContainerSubtitulo:'Trouver la bonne solution pour vous',
            apartamentosContainerParagrafo:'Dans notre logement, vous pouvez trouver différents types d´appartements. Nous avons des appartements pouvant accueillir 1-7 personnes.',
            apartamentosContainerParagrafo2:'Les appartements sont répartis sur 3 étages, sans ascenseur.',
            quartosContainerTitulo:'Nos Chambres',
            quartosContainerSubtitulo:'Trouver la bonne solution pour vous',
            quartosContainerParagrafo:'Dans notre logement, vous pouvez trouver différents types de chambres. Nous avons des chambres avec et sans vue sur la mer.',
            //-----------------------------//
            footerNewsletter:'Notre bulletin d information',
            footerNewsletterBTN:'SAbboner',
            footerNewsletterPlaceHolder:'Entrez votre e-mail',
            //-----------------------------//
            alojamentoContainerTitulo:'Hébergement Batata',
            alojamentoContainerSubtitulo:'Les yeux sur la mer',
            alojamentoContainerParagrafo:'Notre hébergement est situé sur l avenue Manuel Remígio (à côté de la mer). Il se compose de 8 appartements et 4 chambres.',
            alojamentoContainerParagrafov2:'2 appartements et 2 chambres avec vue sur la mer, 6 appartements et 2 chambres sans vue sur la mer. Tous les appartements sont élégamment meublés, modernes et audacieux. Ils sont équipés d appareils nécessaires pour un excellent séjour, WI-FI, des draps et des articles de toilette. Nous avons une réception dans le logement, où nous offrons un soutien à nos clients, ce qui nous distingue de la plupart des logements de Nazaré.',
            alojamentoContainerBTNReservar:'RÉSERVEZ DÈS MAINTENANT!',
            //-----------------------------//
            FormTitleV1:'Remplissez les champs',
            FormTitleV2:'Demande d information',
            FormSubtitle:'Données personnelles',
            FormApartamentoTitle:'Choisissez l appartement',
            formApartamentoPlaceholder:'Choisissez votre appartement',
            formOfertaTitle:'Offres spéciales',
            formOfertaPlaceholder:'Choisissez une offre',
            formNomePlaceholder:'Nom',
            formEmailPlaceholder:'Email',
            formTelefonePlaceholder:'Téléphone',
            formMensagemPlaceholder:'Message',
            formRSVPlaceholder:'Demande d information',
            formBottomTitle:'Information',
            formBottomSubtitle:'À prendre en compte.',
            formBottomText:'Nous n avons pas de service de paiement online. Après la demande de réservation, vous serez contacté par le logement pour procéder à la conclusion de la même. Votre réservation ne sera complétée que lorsque nous vous enverrons un courrier électronique avec les informations de la même. Ne perdez pas de temps!',
            formAdultosTitle:'Nº De',
            //-----------------------------//
            ServicosTitulo:'Services inclus',
            ServicosSubtitulo:'Nous fournissons',
            Servicosv2Titulo:'Services supplémentaires',
            Servicosv2Subtitulo:'Par réservation',
            
            //-----------------------------//
            ofertaListTitulo:'Offres Spéciales',
            ofertaListSubtitulo:'Profitez de nos offres',            
            opcao1ofertas:'option 1',
            opcao2ofertas:'option 2',
            ofertaBTN:'En Savoir Plus',
            ofertaRibbon:'De',
            ofertaRSVBTN:'Livre',
            ofertaRSVBTN2:'Profitez de cette promotion!',
            ofertaCLSBTN:'Près',
            //-----------------------------//
            apartamentoDescricaoTitulo:'Description',
            apartamentoDescricaoSubtitulo:'Détails de notre propriété',
            apartamentoComodidadeTitulo:'Aménagements',
            apartamentoComodidadeSubtitulo:'Nos appartements ont',
            apartamentoInformacaoTitulo:'Informations utiles',
            apartamentoInformacaoSubtitulo:'À savoir ...',
            apartamentoComentarioTitulo:'Ce que disent nos clients',
            apartamentoVoltaNazareTitulo:'Autour de Nazaré',
            apartamentoVoltaNazareSubtitulo:'Nous sommes au centre!',
            apartamentoApartSimiliaresTitulo:'Appartements Similaires',
            apartamentoApartSimiliaresSubtitulo:'Connaître tous nos appartements',
            apartamentoBTNTexto:'Réservez cet appartement',
            apartamentoBTNTextuBTN:'VÉRIFIER LA DISPONIBILITÉ',
            apartamentoBTNFotos:'Voir les photos',
            apartamentoBTNPartilhar:'Part',
            //-----------------------------//
            nazareTituloSub:'Un village plein d histoire',
            nazareDesc1:'Nazaré est un village portugais situé sur la côte d argent, avec environ 10 300 habitants. Visité annuellement par des milliers de touristes nationaux et étrangers, Nazaré est aujourd hui une référence mondiale de SURF. Une magnifique plage, avec un promontoire qui entre dans la mer, fait de cette plage l une des 10 plus belles au Portugal.',
            nazareDesc2:'Pour les amateurs de gastronomie, Nazaré dispose d excellents restaurants au poisson frais et aux fruits de mer, proposant différentes variétés de plats, comme les pâtes de poisson, les sardines grillées, la loup de mer grillée et la loup de mer et de délicieux fruits de mer.',
            nazareVisitarTitulo:'Que visiter',
            nazareVisitarSubtitulo:'Notre suggestion',
            nazareComoChegarTitulo:'Comment se rendre à Nazaré',
            nazareComoChegarSubtitulo:'Bon voyage!',
            nazareSurfText:'Nazaré est maintenant connu mondialement à cause d images comme celle-ci. Les grandes vagues, parfaites et incroyables, font de Praia do Norte une référence mondiale pour le surf.',
            nazareBTNTexto:'Venez visiter Nazaré',
            nazareBTNTextuBTN:'VÉRIFIER LA DISPONIBILITÉ',
             //-------------------------------------//
             contactosTitulo:'Avez vous une question?',
             contactosSubtitulo:'Éclaircissons leurs doutes',
             contactosLeftTitulo:'Avez vous une question?',
             contactosLeftSubtitulo:'Nous attendons!',
             contactosLeftTituloRedes:'Les réseaux sociaux',
             contactosRightTitulo:'Menssage',
             contactosRightNome:'Nom',
             contactosRightEmail:'Email',
             contactosRightMensagem:'Menssage',
             contactosRightFormBTN:'Envoyer',
             contactosBTN:'RÉSERVEZ DÈS MAINTENANT',
             //-------------------------------------//
            modalFormSubmitFormulario:'Votre demande d information a été envoyée. Nous serons brefs dans notre réponse. Merci',
            modalFormSubmitContactos:'Merci pour votre contact. Nous serons brefs dans notre réponse',
            modalFormSubmitNewsletter:'Merci de vous abonner à notre bulletin d information',
            
            
        },
        en:{ // Inglês
            liAlojamento: 'Accomodation',
            liApartamentos: 'Apartments',
            liOfertas: 'Offers',
            liNazaré: 'Nazaré',
            liContactos: 'Contacts',
            btnReservarNav: 'BOOK NOW',
            //-----------------------------//
            containerRSV: 'Book Now',
            containerRSV2: 'Best price guaranteed',
            containerRSV3: 'Guests',
            containerRSVbtn: 'Check availability',
            //-----------------------------//
            homeContainerTitulo:'Batata Accommodation',
            homeContainerSubtitulo:'An accommodation with eyes on the sea.',
            homeContainer: 'Our accommodation is situated on the Silver Coast, Nazaré, a fishing village with lots of history, and many traditions. In total there are 8 apartments and 4 rooms, located in a building by the sea.',
            homeContainer2: 'An accommodation that differentiates itself by the quality and service provided to all guests during their stay. We care about the details in order to provide a wonderful stay.',
            //-----------------------------//
            homeApartamentosContainerTitulo:'Explore Accommodation',
            homeApartamentosContainerPreço:'from',
            //-----------------------------//
            homeComentariosContainerTitulo:'What our customers say',
            //-----------------------------//
            homeOfertasContainerTitulo:'Special offers',
            //-----------------------------//
            apartamentosContainerTitulo:'Our Apartments',
            apartamentosContainerSubtitulo:'Find the right solution for you',
            apartamentosContainerParagrafo:'In our accommodation you can find several types of Apartments. We have apartments for 1 to 7 people.',
            apartamentosContainerParagrafo2:'The apartments are divided over 3 floors without a lift.',
            quartosContainerTitulo:'Our Rooms',
            quartosContainerSubtitulo:'Find the right solution for you',
            quartosContainerParagrafo:'In our accommodation you can find several types of rooms. We have rooms with and without sea view.',
            //-----------------------------//
            footerNewsletter:'Our newsletter',
            footerNewsletterBTN:'Subscribe',
            footerNewsletterPlaceHolder:'Insert your email',
            //-----------------------------//
            alojamentoContainerTitulo:'Accommodation Batata',
            alojamentoContainerSubtitulo:'With eyes on the sea',
            alojamentoContainerParagrafo:'Our accommodation is situated on Manuel Remígio Avenue (in front to the sea). It consists of 8 apartments and 4 bedrooms.',
            alojamentoContainerParagrafov2:'2 Apartments and 2 rooms with sea view, 6 apartments and 2 rooms without sea view. All apartments are elegantly furnished and modern. They are equipped with necessary appliances for a great stay, WI-FI, sheets, and personal hygiene products. We have a reception in the accommodation, where we offer support to our guests, we distinguish ourselves from most accommodation in Nazaré.',
            alojamentoContainerBTNReservar:'BOOK NOW!',
            //-----------------------------//
            FormTitleV1:'Fill in the fields',
            FormTitleV2:'Fill in the fields',
            FormSubtitle:'Personal Details',
            FormApartamentoTitle:'Choose the Apartment',
            formApartamentoPlaceholder:'Choose your Apartment',
            formOfertaTitle:'Special Offers',
            formOfertaPlaceholder:'Choose an offer',
            formNomePlaceholder:'Name',
            formEmailPlaceholder:'Email',
            formTelefonePlaceholder:'Telephone',
            formMensagemPlaceholder:'Message',
            formRSVPlaceholder:'Fill in the fields',
            formBottomTitle:'Information',
            formBottomSubtitle:'To have in count.',
            formBottomText:'We dont have online payment service. After the reservation request, you will be contacted by the accommodation to proceed to the conclusion of the same. Your reservation will only be completed when we send you an email with the information. Do not waste time!',
            formAdultosTitle:'Nº Of',
            //-----------------------------//
            ServicosTitulo:'Included Services',
            ServicosSubtitulo:'We have at your disposal',
            Servicosv2Titulo:'Extra Services',
            Servicosv2Subtitulo:'By appointment',
            
            //-----------------------------//
            ofertaListTitulo:'Special offers',
            ofertaListSubtitulo:'Enjoy our offers',
            opcao1ofertas:'option 1',
            opcao2ofertas:'option 2',
            ofertaBTN:'More Information',
            ofertaRibbon:'From',
            ofertaRSVBTN:'Book',
            ofertaRSVBTN2:'Enjoy this promotion!',
            ofertaCLSBTN:'Close',
            //-----------------------------//
            apartamentoDescricaoTitulo:'Description',
            apartamentoDescricaoSubtitulo:'Details of our property',
            apartamentoComodidadeTitulo:'Amenities',
            apartamentoComodidadeSubtitulo:'Our apartments have',
            apartamentoInformacaoTitulo:'Useful information',
            apartamentoInformacaoSubtitulo:'To know...',
            apartamentoComentarioTitulo:'What our customers say',
            apartamentoVoltaNazareTitulo:'Around Nazaré',
            apartamentoVoltaNazareSubtitulo:'We are in the center!',
            apartamentoApartSimiliaresTitulo:'Similar Apartments',
            apartamentoApartSimiliaresSubtitulo:'Know all our apartments',
            apartamentoBTNTexto:'Book this apartment',
            apartamentoBTNTextuBTN:'CHECK AVAILABILITY',
            apartamentoBTNFotos:'See pictures',
            apartamentoBTNPartilhar:'Share',
            //-----------------------------//
            nazareTituloSub:'A village full of history',
            nazareDesc1:'Nazaré is a Portuguese village situated on the Silver Coast, with about 10 300 population.Visited annually by thousands of domestic and foreign tourists, Nazaré is today a world reference of SURF. A magnificent beach, with a promontory that enter the sea, make this beach one of the 10 most beautiful in Portugal.',
            nazareDesc2:'For lovers of gastronomy, Nazaré has excellent restaurants with fresh fish and seafood, offering different varieties of dishes, such as fish pasta, grilled sardines, grilled sea bass and sea bass and delicious seafood.',
            nazareVisitarTitulo:'What to visit',
            nazareVisitarSubtitulo:'Our suggestion',
            nazareComoChegarTitulo:'How to get to Nazaré',
            nazareComoChegarSubtitulo:'Good trip!',
            nazareSurfText:'Nazaré today is known worldwide because of images like this. Great waves and perfect, make Praia do Norte a world reference for surfing big waves.',
            nazareBTNTexto:'Come to visit Nazaré',
            nazareBTNTextuBTN:'CHECK AVAILABILITY',
             //-------------------------------------//
            contactosTitulo:'Do you have a question?',
            contactosSubtitulo:'We clarify your doubts',
            contactosLeftTitulo:'Do you have a question?',
            contactosLeftSubtitulo:'We are waiting for you!',
            contactosLeftTituloRedes:'Social Networks',
            contactosRightTitulo:'Message',
            contactosRightNome:'Name',
            contactosRightEmail:'Email',
            contactosRightMensagem:'Message',
            contactosRightFormBTN:'Send',
            contactosBTN:'BOOK NOW',
             //-------------------------------------//
            modalFormSubmitFormulario:'Your request for information has been sent. We will be brief in our response. Thank you',
            modalFormSubmitContactos:'Thank you for your contact. We will be brief in our response',
            modalFormSubmitNewsletter:'Thank you for subscribing to our newsletter.',
        },
        es:{ // Espanhol
            liAlojamento: 'Alojamiento',
            liApartamentos: 'Habitaciones',
            liOfertas: 'Ofertas',
            liNazaré: 'Nazaré',
            liContactos: 'Contacto',
            btnReservarNav: 'Reservar',
            //-----------------------------//
            containerRSV: 'Reserva Ya',
            containerRSV2: 'Mejor precio garantizado',
            containerRSV3: 'Los huéspedes',
            containerRSVbtn: 'Ver disponibilidad',
            //-----------------------------//
            homeContainerTitulo:'Alojamiento Batata',
            homeContainerSubtitulo:'Un alojamiento con los ojos en el mar.',
            homeContainer: 'Nuestro alojamiento está situado en la Costa de Plata, Nazaré, un pueblo pesquero con mucha historia, y muchas tradiciones. En total son 8 Apartamento y 4 habitaciones, ubicados en un edificio junto al mar. Un alojamiento que se diferencia por la calidad, y el servicio a todos los huéspedes durante su estancia. Preocupamos con el detalle para poder proporcionar una mágnifica estancia.',
            //-----------------------------//
            homeApartamentosContainerTitulo:'Explore el alojamiento',
            homeApartamentosContainerPreço:'desde',
            //-----------------------------//
            homeComentariosContainerTitulo:'Lo que dicen nuestros clientes',
            //-----------------------------//
            homeOfertasContainerTitulo:'Ofertas especiales',
            //-----------------------------//
            apartamentosContainerTitulo:'Nuestros apartamentos',
            apartamentosContainerSubtitulo:'Encuentre la solución ideal para usted',
            apartamentosContainerParagrafo:'En nuestro alojamiento puede encontrar varias tipologías de apartamentos. Tenemos apartamentos con capacidad de 1 a 7 personas.',
            apartamentosContainerParagrafo2:'Los apartamentos están divididos en 3 plantas, sin ascensor.',
            quartosContainerTitulo:'Nuestras Habitaciones',
            quartosContainerSubtitulo:'Encuentre la solución ideal para usted',
            quartosContainerParagrafo:'En nuestro alojamiento puede encontrar varios tipos de habitaciones. Tenemos habitaciones con y sin vistas al mar.',
            //-----------------------------//
            footerNewsletter:'Nuestro boletín de noticias',
            footerNewsletterBTN:'Subscribirse',
            footerNewsletterPlaceHolder:'Insertar su email',
            //-----------------------------//
            alojamentoContainerTitulo:'Alojamiento Batata',
            alojamentoContainerSubtitulo:'Con los ojos en el mar',
            alojamentoContainerParagrafo:'Nuestro alojamiento está situado en la Avenida Manuel Remigio (junto al mar). Está compuesto por 8 apartamentos y 4 dormitorios.',
            alojamentoContainerParagrafov2:'2 Apartamientos y 2 habitaciones con vistas al mar, 6 apartamentos y 2 habitaciones sin vistas al mar. Todos los apartamentos cuentan con una decoración elegante, moderna y arrojada. Están equipados con electrodomésticos necesarios para una excelente estancia, WI-FI, sábanas, y productos de higiene personal. Contamos con una recepción en el alojamiento, donde ofrecemos apoyo a nuestros huéspedes, diferenciándonos así, de la mayoría de los alojamientos en Nazaré.',
            alojamentoContainerBTNReservar:'RESERVE AHORA!',
            //-----------------------------//
            FormTitleV1:'Preencha os campos',
            FormTitleV2:'Pedido de informações',
            FormSubtitle:'Datos personales',
            FormApartamentoTitle:'Escolher o Apartamento',
            formApartamentoPlaceholder:'Escolher o seu Apartamento',
            formOfertaTitle:'Ofertas especiales',
            formOfertaPlaceholder:'Elija una oferta',
            formNomePlaceholder:'Nombre',
            formEmailPlaceholder:'Email',
            formTelefonePlaceholder:'Teléfono',
            formMensagemPlaceholder:'Mensagem',
            formRSVPlaceholder:'Pedir Informações',
            formBottomTitle:'Información',
            formBottomSubtitle:'A tener en cuenta.',
            formBottomText:'No tenemos servicio de pago online. Después de la solicitud de reserva, será contactado por el alojamiento para proceder a la conclusión de la reserva. Su reserva sólo estará completa cuando le enviamos un mail con la información de la misma. No pierda tiempo!',
            formAdultosTitle:'Nº De',
            //-----------------------------//
            ofertaListTitulo:'Ofertas especiales',
            ofertaListSubtitulo:'Disfrute de nuestras ofertas',
            opcao1ofertas:'opcão 1',
            opcao2ofertas:'opcão 2',
            ofertaBTN:'Saber Mais',
            ofertaRibbon:'Desde',
            ofertaRSVBTN:'Reservar',
            ofertaRSVBTN2:'Aprovecha esta oportunidad!',
            ofertaCLSBTN:'Cerrar',
            //-----------------------------//
            apartamentoDescricaoTitulo:'Descripción',
            apartamentoDescricaoSubtitulo:'Detalles de nuestra propiedad',
            apartamentoComodidadeTitulo:'Amenities',
            apartamentoComodidadeSubtitulo:'Comodidad',
            apartamentoInformacaoTitulo:'Nuestros apartamentos tienen',
            apartamentoInformacaoSubtitulo:'A saber...',
            apartamentoComentarioTitulo:'Lo que dicen nuestros clientes',
            apartamentoVoltaNazareTitulo:'Alrededor de Nazaré',
            apartamentoVoltaNazareSubtitulo:'Estamos en el centro!',
            apartamentoApartSimiliaresTitulo:'Apartamentos Similares',
            apartamentoApartSimiliaresSubtitulo:'Conozca todos nuestros apartamentos',
            apartamentoBTNTexto:'Reserve este apartamento',
            apartamentoBTNTextuBTN:'VER DISPONIBILIDAD',
            apartamentoBTNFotos:'Ver fotos',
            apartamentoBTNPartilhar:'Partilhe',
            //-----------------------------//
            nazareTituloSub:'Una aldea llena de historia',
            nazareDesc1:'Nazaré es una villa portuguesa situada en la Costa de Plata, con cerca de 10 300 habitantes. Visitada anualmente por miles de turistas nacionales y extranjeros, Nazaré es hoy una referencia mundial del SURF.',
            nazareDesc2:'Una magnífica playa, con un promontorio que entra por el mar, hace de esta playa una de las 10 más bellas de Portugal. Para los amantes de la gastronomía, Nazaré cuenta con excelentes restaurantes de pescado fresco y marisco, ofreciendo así diferentes variedades de platos, tales como pasta de pescado, sardinas asadas, cherne y robalo a la parrilla y al delicioso marisco.',
            nazareVisitarTitulo:'Qué visitar',
            nazareVisitarSubtitulo:'Nuestra sugerencia',
            nazareComoChegarTitulo:'Cómo llegar a Nazaré',
            nazareComoChegarSubtitulo:'Buen viaje!',
            nazareSurfText:'Nazaré es hoy conocido mundialmente, debido a imágenes como ésta. Olas grandes, perfectas e increíbles, hacen de Praia do Norte una referencia mundial para el surf.',
            nazareBTNTexto:'Venga a visitar a Nazaré',
            nazareBTNTextuBTN:'VER DISPONIBILIDAD',
            //-------------------------------------//
            contactosTitulo:'Tiene alguna pregunta?',
            contactosSubtitulo:'Aclaramos sus dudas',
            contactosLeftTitulo:'Nuestros contactos',
            contactosLeftSubtitulo:'Estamos a su espera!',
            contactosLeftTituloRedes:'Redes sociales',
            contactosRightTitulo:'Mensaje',
            contactosRightNome:'Nombre',
            contactosRightEmail:'Email',
            contactosRightMensagem:'Messaje',
            contactosRightFormBTN:'Enviar',
            contactosBTN:'RESERVE AHORA!',
            //-------------------------------------//
           modalFormSubmitFormulario:'Su solicitud de información ha sido enviada. Seremos breves en nuestra respuesta. Gracias.',
           modalFormSubmitContactos:'Gracias por su contacto. Seremos breves en nuestra respuesta',
           modalFormSubmitNewsletter:'Gracias por suscribirse a nuestro boletín de noticias.',
        },
    },
    lang2:{

    },
}