import React, { Component } from 'react'
import Pagina from '../components/PaginaReservar'
const $ = window.$;

class Reservar extends Component {
    componentDidMount() {
        document.title = 'Reservar';
        $('#mapTop').height("0");
      }
    render() {
        return (
            <Pagina/>
        )
    }
}
export default Reservar

