import React, { Component } from 'react'
import Apresentacao from'../components/Apresentacao'
import ApartamentosPP from '../components/ApartamentosPP'
import OfertasPP from '../components/OfertasPPV2'
import SliderR from '../components/SliderR'
import Slider from '../components/SliderShow'

import Translator from '../components/Translator';


import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pt";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/es";

const $ = window.$;

export default class PaginaHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkin: moment(new Date()).format("DD/MM/YYYY"),
            checkout: moment(new Date()).add(1, 'day').format("DD/MM/YYYY"),
            checkin2: undefined,
            checkout2: undefined,
            hospedes: '1',
            locale: localStorage.lang,
            localeDay: undefined,
        };
    }
    
    
    handleCheckin = (day) => {
        var date = moment(day).locale(this.state.localeDay).format("DD/MMMM");
        var dateof = moment(day).format("DD/MM/YYYY");
        
        this.setState({ checkin: dateof,
                        checkin2: date });
    }
    handleCheckout = (day) => {
        var date = moment(day).locale(this.state.localeDay).format("DD/MMMM");
        var dateof = moment(day).format("DD/MM/YYYY");


        this.setState({ checkout: dateof,
                        checkout2: date });
    }
    handleHospedes = (e) => {
        this.setState({ hospedes: e.target.value });
    }
    handleCheckAvailabilty = () => {
        this.props.history.push('/reservare', {
            myProp: this.state,
        })
    }

    toggleSlider = ( ) => {
        var wrapperHV2D  = $('#mainScreenV2').css('display');
        if(wrapperHV2D === "flex"){
        var windowH = $(window).height();
        var wrapperH = $('#mainScreen').height();
        var wrapperHV2 = $('#mainScreenV2').height();
        var wrapperHV2D  = $('#mainScreenV2').css('display');
        var headerH = $('#headerFull').height();
        var finalHeight = windowH - wrapperHV2 - headerH ;
        $('#SliderHomeHeight').height(finalHeight);
        }else{
        var windowH = $(window).height();
        var wrapperH = $('#mainScreen').height();
        var wrapperHV3 = $('#mainScreenV3').height();
        var headerH = $('#headerFull').height();
        var finalHeight = windowH - wrapperHV3 - headerH - 100 ;
        $('#SliderHomeHeight').height(finalHeight);
        }
    }

    componentDidMount(){
        if(localStorage.lang === undefined){
            this.setState({
            checkin2: moment(new Date()).locale("pt").format("DD/MMMM"),
            checkout2: moment(new Date()).locale("pt").add(1, 'day').format("DD/MMMM"),
            localeDay: "pt"
            })
        }else{
            this.setState({
                checkin2: moment(new Date()).locale(this.state.locale).format("DD/MMMM"),
                checkout2: moment(new Date()).locale(this.state.locale).add(1, 'day').format("DD/MMMM"),
                localeDay: this.state.locale
                })
        }
        $('#mapTop').height("0");
        this.toggleSlider();
        
    }


    render() {
        const { checkin,checkout,hospedes,checkin2,checkout2 } = this.state;

        const BTN = <Translator name="contactosBTN"/>;

        return (
            <>
            <div id="mainScreen">
                <Slider/>
            </div>
                <div className="containerRSV" id="mainScreenV2">
                    <div className="containerRSVLEFT">
                        <h4><Translator name="containerRSV"/></h4>
                        <p><Translator name="containerRSV2"/></p>
                    </div>
                    <div className="containerRSVCENTER">
                        <span className="inputRSV">
                        <h4>Check In</h4>
                        <DayPickerInput 
                            onDayChange={this.handleCheckin}
                            value = {checkin2}
                            dayPickerProps={{
                                numberOfMonths: 1,
                                localeUtils: MomentLocaleUtils,
                                locale: this.state.localeDay,
                                
                            }}
                            
                        />
                        </span>
                        <span className="inputRSV">
                        <h4>Check Out</h4>
                        <DayPickerInput 
                            onDayChange={this.handleCheckout}
                            value = {checkout2}
                            dayPickerProps={{
                                numberOfMonths: 1,
                                localeUtils: MomentLocaleUtils,
                                locale:this.state.localeDay,
                            }}
                            
                            
                            
                        />
                        </span>
                        <span className="inputRSV" style={{flexGrow:"4"}}>
                        <h4>Hospedes</h4>
                        <select className="inputRSVtext" type="text" value={hospedes} onChange={this.handleHospedes} style={{border:'none',backgroundColor:'#FFF',flexGrow:'2'}}>
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option>
                                                                <option>5</option>
                                                                <option>6</option>
                                                                <option>7</option>
                                                                <option>8</option>
                        </select>
                        
                        </span>
                    </div>
                    <div className="containerRSVRIGHT">
                    <button className="verdisponibilidade" onClick={this.handleCheckAvailabilty}><Translator name="containerRSVbtn"/></button>
                    </div>
                </div>
            
            <div className="alojamentopfh" id="mainScreenV3">
                        <div className="alojementopfa">
                            <span>
                            <a id="alojamentoa" href="./reservar">{BTN}</a>
                            </span>
                        </div>
                    </div>
            <Apresentacao/>
            <ApartamentosPP/>
            <SliderR />
            <OfertasPP/>
            </>
        )
    }

   
}

