import React from "react"
import ListaOfertas from './OfertasList'
import { withOfertaConsumer } from '../context'
import Loading from './Loading'

function OfertasContainer({context}){
    const {loading, Ofertas} = context;
    if(loading){
        return <Loading/>
    }
    return (
        <>
            <ListaOfertas Ofertas={Ofertas}/>
        </>
    )
}

export default withOfertaConsumer(OfertasContainer)
