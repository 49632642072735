import React, { Component } from 'react'
import logo from '../images/logo1.png'
import {Link} from 'react-router-dom'
import {FaCloudSun} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'
import {FaSkype} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'
import Tempo from '../components/Weather'
import Translator from '../components/Translator';

import emailjs from 'emailjs-com';
import config from '../Email3';

const $ = window.$;

export default class Footer extends Component {

    state={
        send: false,
    }

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(config.SERVICE_ID, config.TEMPLATE_ID, e.target, config.USER_ID)
            .then((result) => {
                document.getElementById("mailForm").reset();
                this.state = { send : true}
                $('#modalNewsletter').modal('toggle');
            }, (error) => {
                console.log(error.text);
            });
    }

    handleClick = () =>{
        this.state = ({ send : false})
    }

    render() {

        
        return (
            <footer className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row" style={{marginLeft:"-15px"}}>
                            <div className="contacts col col-lg-3 col-md-4 col-sm-5 col-xs-12">
                                <div className="footerlogo">
                                    <img src={logo} alt="img1"></img>
                                </div>
                                <h5>ALOJAMENTO BATATA</h5>
                                <p>Rua Branco Martins, 24A</p>
                                <p>2450-060 Nazaré, Portugal</p>
                            </div>
                            <div className="linksxs hidden-xs hidden-sm col col-lg-4 col-md-3">
                                <ul className="nav-pills">
                                    <li role="presentation"><Link to='/alojamentos'><Translator name="liAlojamento"/></Link></li>
                                    <li role="presentation"><Link to='/apartamentos'><Translator name="liApartamentos"/></Link></li>
                                    <li role="presentation"><Link to='/ofertas'><Translator name="liOfertas"/></Link></li>
                                    <li role="presentation"><Link to='/nazare'><Translator name="liNazaré"/></Link></li>
                                    <li role="presentation"><Link to='/contactos'><Translator name="liContactos"/></Link></li>
                                </ul>
                            </div>
                            <div className="newsletter2 visible-xs-block newsletter col col-xs-12" style={{display: "none"}}>
                                <div className="footer-newsletter">
                                    <form onSubmit={this.sendEmail} id="mailForm">
                                        <label className="control-label"><Translator name="footerNewsletter"/></label>
                                        <span className="input-group"><input type="text" placeholder="Email" name="email" className="placeholderNewsletter form-control"/>
                                        <span className="input-group-btn">
                                            <button type="submit" value="Send" onClick={this.handleClick} className="btn btn-default">
                                            <Translator name="footerNewsletterBTN"/></button></span></span>
                                    </form>
                                </div>
                            </div>
                            <div className="temp col col-md-2 col-sm-3 col-xs-12 visibletemp">
                                <div className="row">
                                    <div className="col-sm-12 col-xs-6">
                                        <div className="weather text-center">
                                            <i style={{fontSize:"40px",marginRight:"10px",verticalAlign:"middle"}}><FaCloudSun/></i>
                                            <div className="temp"><Tempo/></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xs-6">
                                        <div className="footer-social">
                                            <a href="skype:aurelio.cavaleiro?call" target="_blank" rel="noopener noreferrer"><span><FaSkype/></span></a>
                                            <a href="https://www.facebook.com/alojamentobatata" target="_blank" rel="noopener noreferrer"><span><FaFacebookF/></span></a>
                                            <a href="https://www.linkedin.com/in/alojamento-batata-868a48144/" target="_blank" rel="noopener noreferrer"><span><FaLinkedinIn/></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="newsletter hidden-xs col col-md-3 col-sm-4 newsletterhidden">
                        <div className="footer-newsletter">
                            <form onSubmit={this.sendEmail} id="mailForm">
                                <label className="control-label"><Translator name="footerNewsletter"/></label>
                                <span className="input-group">
                                    <input type="text" placeholder="Email" name="email" className="placeholderNewsletter form-control" required/>
                                    <span className="input-group-btn">
                                        <button type="submit" value="Send" onClick={this.handleClick} className="btn btn-default"><Translator name="footerNewsletterBTN"/></button></span></span>
                                        <div class="modal fade" id="modalNewsletter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{height: "auto"}}>
                                            <div class="modal-dialog cascading-modal" role="document" style={{height: "auto"}}>
                                                <div class="modal-content" style={{height: "auto"}}>   
                                                    <div class="modal-header light-blue darken-3 white-text" style={{height: "auto"}} >
                                                        <h4 class="title"><i class="fas fa-users"></i></h4>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>                 
                                                    <div class="modal-body mb-0 text-center" style={{height: "auto"}}>                           
                                                        <h2 style={{color:"black",fontSize:"15px",paddingTop:"0px",paddingLeft:"0px"}}><Translator name="modalFormSubmitNewsletter"/></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </form>
                        </div>
                        </div>
                        <div className="temp col col-md-2 col-sm-3 col-xs-12 hiddentemp" style={{display:"none"}}>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-6 hiddentempshow">
                                        <div className="weather text-center">
                                            <i style={{fontSize:"40px",marginRight:"10px",verticalAlign:"middle"}}><FaCloudSun/></i>
                                            <div className="temp"><Tempo/></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xs-6 hiddentempshow">
                                        <div className="footer-social">
                                            <a href="skype:aurelio.cavaleiro?call" target="_blank" rel="noopener noreferrer"><span><FaSkype/></span></a>
                                            <a href="https://www.facebook.com/alojamentobatata" target="_blank" rel="noopener noreferrer"><span><FaFacebookF/></span></a>
                                            <a href="https://www.linkedin.com/in/alojamento-batata-868a48144/" target="_blank" rel="noopener noreferrer"><span><FaLinkedinIn/></span></a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="visible-sm-block col col-sm-12 navlihidden" style={{display: "none"}}>
                            <ul className="nav nav-pills">
                                    <li role="presentation"><Link to='/alojamentos'><Translator name="liAlojamento"/></Link></li>
                                    <li role="presentation"><Link to='/apartamentos'><Translator name="liApartamentos"/></Link></li>
                                    <li role="presentation"><Link to='/ofertas'><Translator name="liOfertas"/></Link></li>
                                    <li role="presentation"><Link to='/nazare'><Translator name="liNazaré"/></Link></li>
                                    <li role="presentation"><Link to='/contactos'><Translator name="liContactos"/></Link></li>
                            </ul>
                        </div>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container"> 
                        <div className="row rowvisiblefooter">
                            <div className="col-sm-6 col-xs-12">
                                <div className="textfb">2020 Alojamento Batata. Todos os direitos reservados</div>
                            </div>
                            <div className="displayContactos col-sm-6 col-xs-12">
                                <div className="telefonef">
                                +351 919 245 867
                                </div>
                                <div className="emailf">
                                <a href="mailto:reservas@alojamentobatata.com">reservas@alojamentobatata.com</a>
                                </div>
                                
                            </div>
                        </div>
                        <div className="row rowvisiblehidden" style={{display:"none"}}>
                            <div className="col-sm-6 col-xs-12 hiddentextfb">
                                <div className="textfb">2020 Alojamento Batata. Todos os direitos reservados</div>
                            </div>
                            <div className="displayContactos col-sm-6 col-xs-12">
                                <div className="telefonef">
                                +351 919 245 867
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )}
}
