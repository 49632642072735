import React, { Component } from 'react'
import tempo from '../tempo';
import axios from 'axios';

class Weather extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
        };
        async componentDidMount() {
            const URL = (`https://${tempo.baseurl}/weather?id=${tempo.cityID}&units=metric&appid=${tempo.key}`)
            let response = await axios.get(URL);
            let data  = response.data;
            this.setState({ data });
        }
    render() {
        let data = this.state.data;
        return(
           <p> {Math.round(data && data.main.temp)}º</p>
        );
    }
}

export default Weather;