import React from 'react';
import Constant from '../constant';
import MyContext from '../context/contextlang';

export default class Translator extends React.Component {
    render(){
        let name = this.props.name;
        return(
            <MyContext.Consumer>
                {context => {
                    let text = Constant.lang[context.lang][name];
                    return( 
                        text ? text:''
                        );
                }}
            </MyContext.Consumer>
        );
    }
}