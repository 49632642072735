import React, { Component } from 'react'
import Client from '../Contentful'
import Servicos from '../components/ServicosNazare'
import Servicos2 from '../components/ServicosNazare2'
import Surfing from '../images/surf.jpg'
import Titulo from '../components/TituloTexto'
import Translator from '../components/Translator';

export default class PaginaNazaré extends Component {

    state={
        AHero: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "nazarePagina"
            });
            let AHero = this.formatData(response.items);
            this.setState({
                AHero,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let AHero = {...item.fields}
            return AHero;
            });
        return tempItems2;
    }

    render(){
        const {AHero} = this.state;

        const Subtitulo = <Translator name="nazareTituloSub"/>;

        const DescTit = <Translator name="nazareDesc1"/>;
        const DescSub = <Translator name="nazareDesc2"/>;

        const SurfTexto = <Translator name="nazareSurfText"/>;

        const BTNRSVTit = <Translator name="nazareBTNTexto"/>;
        const BTNRSVSub = <Translator name="nazareBTNTextuBTN"/>;

        return(
            <>
            <div className="HeroIMGN">
            {AHero.map(ahero => <img key={ahero.imgBanner.fields.file.url} src={ahero.imgBanner.fields.file.url} alt="img1"></img>)}
            </div>
            <div className="apblockN">
                <div className ="wrapper-apN">
                <article className="img-infoapN">
                <Titulo title="Nazaré-Portugal" subtitle={Subtitulo}/>
                <p>{DescTit}</p>
                <p>{DescSub}</p>
                </article>
                {AHero.map(ahero => <img className="img-apN" key={ahero.imgTxt.fields.file.url} src={ahero.imgTxt.fields.file.url} alt="img2"></img>)}
                </div>
            </div>
            <Servicos/>
            <Servicos2/>
            <div className="nazarepf">
                    <div className="nazarepfa">
                       <div className="nazarepfleft">
                        <h2>{BTNRSVTit}</h2>
                       </div>
                       <div className="nazarepfright">
                       <span>
                        <a href="./reservar">{BTNRSVSub}</a>
                        </span>
                       </div>
                    </div>
            </div>
            <div className="hero">
            <div className="hero-overlay">
                <h1>SURF</h1>
                <p>{SurfTexto}</p>
            </div>
            <img src={Surfing} className="hero-image" alt="img3"/>
            </div>
            </>
        )
    }
}