import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ApartamentosProvider} from './context'
import {OfertasProvider} from './context'
import {QuartosProvider} from './context'

ReactDOM.render(
  <ApartamentosProvider>
  <OfertasProvider>
  <QuartosProvider>
    <Router>
      <App />
    </Router>
    </QuartosProvider>
  </OfertasProvider>
  </ApartamentosProvider>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
