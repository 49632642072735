import React from 'react'
import Apartamento from './Apartamento'
import Translator from '../components/Translator';

export default function ApartamentosList({Apartamentos}) {
    
    return (

        <div className="container">
            <div className="row" style={{textAlign: "center"}}>
                <div className="col-xs-12">
                    <div className="page-header" style={{display: "inline-block"}}>
                        <h1><Translator name="apartamentosContainerTitulo"/></h1><p><Translator name="apartamentosContainerSubtitulo"/></p>
                        </div>
                </div>
            </div>
            <div className="row rooms-list-text" style={{textAlign: "center"}}>
                <div className="col-xs-12">
                    <p><Translator name="apartamentosContainerParagrafo"/></p>
                    <p><Translator name="apartamentosContainerParagrafo2"/></p>
                    </div>
            </div>
        <section className="roomslist">
            <div className="roomslist-center">
                {
                    Apartamentos.map(item =>{
                        return <Apartamento key={item.id} Apartamento={item}/>
                    })
                }
            </div>
        </section>
        </div>
    )
}
