import React from 'react'
import PropTypes from 'prop-types'
import {FaUserAlt} from 'react-icons/fa'
import Translator from '../components/Translator';


export default function Apartamento( { Apartamento } ) {
    const{nome, slug, preco, multiTexto,imgPrincipal} = Apartamento;
    return (
        <article className="room" onClick={() =>{window.location = `/apartamentos/${slug}`}}>
                <div className="img-container">
                    <img src={imgPrincipal.fields.file.url} alt="single room" />
                </div>
                <div className="room-info">
                    <div className="room-left">
                        <h2>{nome}</h2>
                        <div className="room-left-wrap">
                        <i><FaUserAlt/></i><span>                          
                            
                            { localStorage.lang === 'en' && <h3> {multiTexto.en.adultos} </h3>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h3> {multiTexto.pt.adultos} </h3>}
                            { localStorage.lang === 'es' && <h3> {multiTexto.es.adultos} </h3>}
                            { localStorage.lang === 'fr' && <h3> {multiTexto.fr.adultos} </h3>}
                            
                            </span>
                        </div>
                        </div>
                    <div className="room-right">
                <div className="room-price">
                    {preco}€
                </div>
                <p><Translator name="homeApartamentosContainerPreço"/></p>
                </div>
                </div>
        </article>
    );
}

Apartamento.propTypes = {
    Apartamento:PropTypes.shape({
        nome:PropTypes.string.isRequired,
        slug:PropTypes.string.isRequired,
        preco:PropTypes.number.isRequired
    })
}
