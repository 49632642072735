import React, { Component } from 'react'
import imgap from '../images/home-alojamento.jpg'
import Titulo from '../components/TituloHome'
import Translator from '../components/Translator';

export default class Apresentacao extends Component {
    render() {
            const titulo= <Translator name="homeContainerTitulo"/>
            const subtitulo=<Translator name="homeContainerSubtitulo"/>
        return (
            
        <div className = "apblock">
            <div className ="wrapper-ap">
                <article className="img-infoap">
                <Titulo title={titulo} subtitle={subtitulo}/>
                <p><Translator name="homeContainer"/></p>
                <p><Translator name="homeContainer2"/></p>
                </article>
                <img className="img-ap"src={imgap} alt="imgleft"/>
            </div>
        </div>
        )
    }
}
