import React, { Component } from 'react'
import Client from '../Contentful'


export default class PaginaAlojamentos extends Component {
    state={
        AHero: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "apartamentosPagina"
            });
            let AHero = this.formatData(response.items);
            this.setState({
                AHero,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let AHero = {...item.fields}
            return AHero;
            });
        return tempItems2;
    }

    render() {
        const {AHero} = this.state;
        return (
            <div className="HeroIMGN">
            {AHero.map(ahero => <img key={ahero.imgBanner.fields.file.url} src={ahero.imgBanner.fields.file.url} alt="img1"></img>)}
            </div>
        )
    }
}