import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

export default function OfertaV2( {Oferta} ) {
    const{slug, imgPrincipal, multiTexto} = Oferta;

    
    

    return (
        <Link to={`/ofertas/${slug}`} style={{textDecoration:"none"}} >
        <article className="ofertav2">
            <div className="img-container" >
                <img src={imgPrincipal.fields.file.url} alt="img1"/>
                { localStorage.lang === 'en' && <h3> {multiTexto.en.nome} </h3>}
                { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h3> {multiTexto.pt.nome} </h3>}
                { localStorage.lang === 'es' && <h3> {multiTexto.es.nome} </h3>}
                { localStorage.lang === 'fr' && <h3> {multiTexto.fr.nome} </h3>} 
            </div>
        </article>
        </Link>
    )
}

OfertaV2.propTypes = {
    OfertaV2:PropTypes.shape({
        slug:PropTypes.string.isRequired,
        images:PropTypes.arrayOf(PropTypes.string).isRequired,
        precooferta:PropTypes.number.isRequired
    })
}
