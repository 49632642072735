import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Translator from '../components/Translator';


function Oferta( {Oferta} ) {
    let history = useHistory();

    const{nomeoferta,multiTexto,multiTexto2,multiTexto3,multiTexto4,multiTexto5, slug, imgPrincipal,precooferta,precooferta2} = Oferta;

    const [slugform2, setSlug] = useState();
    
    


    const handleCheckAvailabilty = () => {
        history.push('/reservare', {
            myProp: {
                slugform2: nomeoferta,
                openOferta: true,
            }
        })
    }

    function onClickFuncao (e){

        var lastPart = window.location.href.split("/").pop();
         var lastofertas = "ofertas"
         if(lastPart !== lastofertas && document.getElementById(lastPart) === null){
            return( 
                <div></div>
                );
             
         }
         if(lastPart !== lastofertas){
            document.getElementById(slug).style.visibility="hidden"
            document.getElementById(lastPart).style.visibility="visible"
            document.getElementById(lastPart).style.border="1px solid #2ec3fd"
            
        }
     }

    

    function onClickClose (e){
        document.getElementById(slug).style.visibility="hidden"
        
    }

    useEffect(() => {
        onClickFuncao();
    });

    const preçodisplay = Math.min(precooferta,precooferta2);
  

    return (
        <>
        
        <article className="oferta">
            <div className="containerof">
            <div className="hiddenoferta" id={slug}>
                <div className="opcoesoferta">
                { localStorage.lang === 'en' && <h2> {multiTexto.en.nome} </h2>}
                { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h2> {multiTexto.pt.nome} </h2>}
                { localStorage.lang === 'es' && <h2> {multiTexto.es.nome} </h2>}
                { localStorage.lang === 'fr' && <h2> {multiTexto.fr.nome} </h2>} 
                <div className="opcaooferta">
                <h5><Translator name="opcao1ofertas"/></h5>
                { localStorage.lang === 'en' && <p> {multiTexto3.en.opcao1} </p>}
                { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {multiTexto3.pt.opcao1} </p>}
                { localStorage.lang === 'es' && <p> {multiTexto3.es.opcao1} </p>}
                { localStorage.lang === 'fr' && <p> {multiTexto3.fr.opcao1} </p>} 
                </div>
                <div className="precoofertahidden">
                    {precooferta}€
                </div>
                <div className="opcaooferta">
                <h5><Translator name="opcao2ofertas"/></h5>
                { localStorage.lang === 'en' && <p> {multiTexto4.en.opcao2} </p>}
                { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {multiTexto4.pt.opcao2} </p>}
                { localStorage.lang === 'es' && <p> {multiTexto4.es.opcao2} </p>}
                { localStorage.lang === 'fr' && <p> {multiTexto4.fr.opcao2} </p>} 
                </div>
                <div className="precoofertahidden">
                    {precooferta2}€
                </div>
                    { localStorage.lang === 'en' && multiTexto5 !== undefined && <p className="ofertaadicional"> {multiTexto5.en.descricaoAdicional} </p>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && multiTexto5 !== undefined && <p className="ofertaadicional"> {multiTexto5.pt.descricaoAdicional} </p>}
                    { localStorage.lang === 'es' && multiTexto5 !== undefined && <p className="ofertaadicional"> {multiTexto5.es.descricaoAdicional} </p>}
                    { localStorage.lang === 'fr' && multiTexto5 !== undefined && <p className="ofertaadicional"> {multiTexto5.fr.descricaoAdicional} </p>}
               
                </div>
                <div className="ofertabotoes">
                <button className="btn btn-lg btn-block btn-book" value="submit"  style={{borderRadius:"0px"}} onClick={handleCheckAvailabilty}><span><h4 style={{fontWeight: "bold"}}><Translator name="ofertaRSVBTN"/></h4></span><span><h6><Translator name="ofertaRSVBTN2"/></h6></span></button>
                <Link to={`/ofertas`} onClick={onClickClose} style={{textDecoration:"none"}}>
                <button style={{borderRadius:"0px", marginTop:"0px"}} className="btn btn-lg btn-block btn-close" ><Translator name="ofertaCLSBTN"/></button>
                </Link>
                </div>
            </div>
            <Link to={`/ofertas/${slug}`} style={{textDecoration:"none"}}>
            <div className="showoferta">
            <div className="offertop">
             <div className="img-container-oferta">
                <img src={imgPrincipal.fields.file.url} alt="img1"/>
                <div className="ribbon-wrap">
                <div className="offer-ribbon"></div>
                <div className="ribbon-text">
                    <span><Translator name="ofertaRibbon"/></span>
                    <h3>{preçodisplay}€</h3>
                </div>
                </div>
            </div>
            </div>
            <div className="oferta-info">
                <div className="container-oferta">
                { localStorage.lang === 'en' && <h2> {multiTexto.en.nome} </h2>}
                { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h2> {multiTexto.pt.nome} </h2>}
                { localStorage.lang === 'es' && <h2> {multiTexto.es.nome} </h2>}
                { localStorage.lang === 'fr' && <h2> {multiTexto.fr.nome} </h2>} 
                { localStorage.lang === 'en' && <p> {multiTexto2.en.descricao} </p>}
                { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {multiTexto2.pt.descricao} </p>}
                { localStorage.lang === 'es' && <p> {multiTexto2.es.descricao} </p>}
                { localStorage.lang === 'fr' && <p> {multiTexto2.fr.descricao} </p>} 
                    <button to={`/ofertas/${slug}`} style={{border:"none",background:"transparent",padding:"0px",color:"#504f46",fontSize:"14px",fontWeight:"bold"}}><Translator name="ofertaBTN"/></button>
                    
                </div>
            </div>
            </div>
            </Link>
            </div>
        </article>
        
        </>
    )
}

Oferta.propTypes = {
    Oferta:PropTypes.shape({
        nomeoferta:PropTypes.string.isRequired,
        slug:PropTypes.string.isRequired,
        precooferta:PropTypes.number.isRequired
    })
}

export default Oferta;