import React from 'react'

export default function TituloHome({title, subtitle}) {
    return (
        <div className="row" style={{textAlign: "center"}}>
        <div className="col-xs-12">
            <div className="page-header" style={{display:"inline-block",margin:"30px"}}>
                <h1 style={{fontSize:"50px"}}>{title}</h1>
                <p style={{padding:"10px 0px 0px 0px"}}>{subtitle}</p>
            </div>
        </div>
        </div>
    )
}
