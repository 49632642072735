import React from 'react'

//Adiciona se o childeren para renderizar o banner!!
//Adiciona-se o hero, para adicionar uma classname obrigatoria no header
export default function Hero({children, hero}) {
    return (
        <header className={hero}>
            {children};
        </header>
    )
}


//Com esta linha ao adicionar um hero e esquecer de inserir a classname obrigatoria no mesmo este toma como default o css do .defaultHero
Hero.defaultProps = {
    hero:'defaultHero'
};
