import React, { Component } from 'react'
import Pagina from '../components/PaginaAlojamentos'
const $ = window.$;

class Alojamento extends Component {
    componentDidMount() {
        document.title = 'Alojamento Batata';
        $('#mapTop').height("0");
      }
    render() {
        return (
            <Pagina/>
        )
    }
}

export default Alojamento



