import React, { Component } from 'react'
import {ApartamentosContext} from '../context'
import Loading from './Loading'
import Apartamento1 from './ApartamentoV2'
import Title from './TituloCentro'
import Slider from "react-slick";
import Translator from '../components/Translator';

export default class ApartamentosPP extends Component {
    static contextType = ApartamentosContext;
    render() {
        let {loading, ApartamentosPP: Apartamentos} = 
        this.context;
        Apartamentos = Apartamentos.map( Apartamento =>{
            return <Apartamento1 key = {Apartamento.id} Apartamento={Apartamento}/>
        })

        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }
              },
              {
                breakpoint: 780,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
          
          const titulo= <Translator name="homeApartamentosContainerTitulo"/>
        return ( 
            <section className="featured-rooms">
            
            <Title title={titulo}/>
            <div className="wrapperS">
                <Slider {...settings}
                  
                >
                  {loading ? <Loading/> : Apartamentos}
                </Slider>
            </div>
            </section>

        )
    }
}

