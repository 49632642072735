import React, { Component } from 'react'
import Client from '../Contentful'
import Servicos from '../components/Servicos'
import Servicos2 from '../components/Servicos2'
import Titulo from '../components/TituloTexto'
import Translator from '../components/Translator';


export default class PaginaAlojamentos extends Component {
    state={
        AHero: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "alojamentoPagina"
            });
            let AHero = this.formatData(response.items);
            this.setState({
                AHero,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let AHero = {...item.fields}
            return AHero;
            });
        return tempItems2;
    }
    

    render(){
        const {AHero} = this.state;
        const TituloP = <Translator name="alojamentoContainerTitulo"/>;
        const TituloS = <Translator name="alojamentoContainerSubtitulo"/>;
        return(
            <>
            <div className="HeroIMGN">
            {AHero.map(ahero => <img key={ahero.imgBanner.fields.file.url} src={ahero.imgBanner.fields.file.url} alt="img1"></img>)}
            </div>
            <div className="apblockA">
                <div className ="wrapper-apA">
                <article className="img-infoapA">
                <Titulo title={TituloP} subtitle={TituloS}/>
                <p><Translator name="alojamentoContainerParagrafo"/></p>
                <p><Translator name="alojamentoContainerParagrafov2"/></p>
                </article>
                {AHero.map(ahero => <img className="img-apA" key={ahero.imgTxt.fields.file.url} src={ahero.imgTxt.fields.file.url} alt="img2"></img>)}
                </div>
            </div>
            <Servicos/>
            <Servicos2/>
                <div className="alojamentopf">
                    <div className="alojementopfa">
                        <span>
                        <a id="alojamentoa" href="./reservar"><Translator name="alojamentoContainerBTNReservar"/></a>
                        </span>
                    </div>
                </div>
            </>
        )
    }
}
