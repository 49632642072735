import React, { Component } from 'react'
import Client from '../Contentful'
import Titulo from '../components/Titulo'
import Translator from '../components/Translator';
export default class Servicos2 extends Component {
    state={
        Services: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "servicosHome2"
            });
            let Services = this.formatData(response.items);
            this.setState({
                Services,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let Services = {...item.fields}
            return Services;
            });
        return tempItems2;
    }

    render(){

        const Titulov2 = <Translator name="Servicosv2Titulo"/>;
        const Subitulov2 = <Translator name="Servicosv2Subtitulo"/>;

        return(
            <>
            <section className="services">
                <div className="services-title">
                <Titulo title={Titulov2} subtitle={Subitulov2}/>
                </div>
                <div className="services-center">
                    {this.state.Services.map((item,index2)=>{
                        return <article key={index2} className="service">
                            <div className="leftSH">
                            <span><img src={item.imgServicos.fields.file.url} alt="img1"></img></span>
                            </div>
                            <div className="rightSS">
                            { localStorage.lang === 'en' && 
                                           <h6 style={{color:"#2ec3fd"}}> {item.multiTexto.en.titulo} </h6>
                                            }
                                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h6 style={{color:"#2ec3fd"}}>
                                                {item.multiTexto.pt.titulo}
                                            </h6>
                                            }
                                            { localStorage.lang === 'es' && <h6 style={{color:"#2ec3fd"}}>
                                                {item.multiTexto.es.titulo}
                                            </h6>
                                            }
                                            { localStorage.lang === 'fr' && <h6 style={{color:"#2ec3fd"}}>
                                                {item.multiTexto.fr.titulo}
                                            </h6>
                                            } 
                                            { localStorage.lang === 'en' &&  
                                           <p> {item.multiTexto.en.paragrafo} </p>
                                            }
                                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && 
                                           <p> {item.multiTexto.pt.paragrafo} </p>
                                            }
                                            { localStorage.lang === 'es' && 
                                           <p> {item.multiTexto.es.paragrafo} </p>
                                            }
                                            { localStorage.lang === 'fr' && 
                                           <p> {item.multiTexto.fr.paragrafo} </p>
                                            }
                            </div>
                            </article>
                    })}
                </div>
            </section>
            </>
        )
    }
}
