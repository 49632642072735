import React from 'react'

//Usamos a children para linkar no hero para que possa dar ao botão!!!
export default function BannerError({title,subtitle,extrasubtitle,children}) {
    return (
        <div className="banner">
            <div className ="banner-top">
                <h4>{title}</h4>
            </div>
            <div className ="banner-bottom">
                <p>{subtitle}</p>
                <p>{extrasubtitle}</p>
            </div>
            <div className="btn-primary">
            {children}
            </div>
        </div>
    );
}