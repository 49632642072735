import React, { Component } from 'react'
import Client from './Contentful'


const ApartamentosContext = React.createContext();
const OfertasContext = React.createContext();
const QuartosContext = React.createContext();



// Provider Serve Para Obter A Informacao 
class OfertasProvider extends Component{
    state={
        Ofertas: [],
        OfertasPP: [],
        loading:true,
    };

    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "oferta"
            });
            let Ofertas = this.formatData(response.items);
            let OfertasPP = Ofertas.filter(ofertas => ofertas.featured === true);
            this.setState({
                Ofertas,OfertasPP,loading:false
            });
        } catch (error) {
            console.log(error);
            
        }
    }

    componentDidMount(){
    this.getData();
    };

    formatData(items){
        let tempItems2 = items.map(item =>{
            let id = item.sys.id;
            let oferta = {...item.fields,id}
            return oferta;
        });
        return tempItems2;
    }

    getOferta = (slug) =>{
        let tempOfertas = [...this.state.Ofertas];
        const Oferta = tempOfertas.find((oferta)=>oferta.slug === slug);
        return Oferta;
    }


    render(){
        return (
            <OfertasContext.Provider value = {{ ...this.state, getOferta:this.getOferta }}>
                {this.props.children}
            </OfertasContext.Provider>
        )
    }
}
class ApartamentosProvider extends Component {
    state={
        Apartamentos:[],
        ApartamentosPP: [],
        loading: true
    };
    
    getData = async () =>{
            try {
                let response = await Client.getEntries({
                    content_type: "apartamento"
                });
                let Apartamentos = this.formatData(response.items)
                let ApartamentosPP = Apartamentos.filter(apartamentos => apartamentos.featured === true);
                this.setState({
                Apartamentos,ApartamentosPP,loading:false
                })
            } catch (error) {
                console.log(error);
                
            }
        }

    componentDidMount(){
        this.getData()
    }

    formatData(items){
        let tempItems = items.map(item =>{
            let id = item.sys.id;
            let apartamento = {...item.fields,id}
            return apartamento;
        });
        return tempItems;
    }

    getApartamento = (slug) =>{
        let tempRooms = [...this.state.Apartamentos];
        const Apartamento = tempRooms.find((apartamento)=>apartamento.slug === slug);
        return Apartamento;
    }

    render() {
        return (
            <ApartamentosContext.Provider value={{ ...this.state, getApartamento:this.getApartamento }}>
                {this.props.children}
            </ApartamentosContext.Provider>
        );
    }
}

class QuartosProvider extends Component{
    state={
        Quartos: [],
        loading:true
    };

    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "quarto"
            });
            let Quartos = this.formatData(response.items);
            this.setState({
                Quartos,loading:false
            });
        } catch (error) {
            console.log(error);
            
        }
    }

    componentDidMount(){
    this.getData();
    };

    formatData(items){
        let tempItems2 = items.map(item =>{
            let quarto = {...item.fields}
            return quarto;
        });
        return tempItems2;
    }

    getQuarto = (slug) =>{
        let tempQuartos = [...this.state.Quartos];
        const Quarto = tempQuartos.find((quarto)=>quarto.slug === slug);
        return Quarto;
    }


    render(){
        return (
            <QuartosContext.Provider value = {{ ...this.state, getQuarto:this.getQuarto }}>
                {this.props.children}
            </QuartosContext.Provider>
        )
    }
}


// Consumer Serve Para Utilizar A Informacao 
const ApartamentosConsumer = ApartamentosContext.Consumer;
const OfertasConsumer = OfertasContext.Consumer;
const QuartosConsumer = QuartosContext.Consumer;



export { ApartamentosProvider, ApartamentosConsumer, ApartamentosContext};
export { OfertasProvider, OfertasConsumer, OfertasContext};
export { QuartosProvider, QuartosConsumer, QuartosContext};




export function withApartamentosConsumer(Component){
    return function ConsumerWrapper(props){
        return <ApartamentosConsumer>
            {value => <Component {...props} context={value}/>}
        </ApartamentosConsumer>
    } 
}

export function withOfertaConsumer(Component){
    return function ConsumerWrapper(props){
        return <OfertasConsumer>
            {value => <Component {...props} context={value}/>}
        </OfertasConsumer>
    }
}

export function withQuartosConsumer(Component){
    return function ConsumerWrapper(props){
        return <QuartosConsumer>
            {value => <Component {...props} context={value}/>}
        </QuartosConsumer>
    }
}



