import React, { Component } from 'react'
import logo from '../images/logo.png' 
//import {FaAlignRight} from 'react-icons/fa'
import {Link, NavLink} from 'react-router-dom'
import {FaFacebookF, FaAlignRight, FaSkype, FaLinkedinIn, FaMapMarkerAlt, FaRegEnvelope} from 'react-icons/fa'
import Google from './googleMaps2'
import {NavDropdown} from 'react-bootstrap';
import Translator from '../components/Translator';
import MyContext from '../context/contextlang';


export default class Navbar extends Component {
    //definir o estado para que se a pagina for encolhida a navbar desparace e mostra um botão com uma lista
    constructor(props){
        super(props)
        this.state = {
            show:false,
            open:false,
        };
    }

    toggleMap = () =>{
        this.setState( { show : !this.state.show } )
    }
    
    toggleLinks = ()=>{
        this.setState({ open: !this.state.open })
    }
    
    render() {
        return (
            <>

            <div className="map" id="mapTop"style={ this.state.show?{height:'500px',transition:'1s'}: {height:'0',transition:'1s'} }>
            <Google />
            </div>
            <header className="header" id="headerFull">
                <div className="header-top">
                    <div className="container-header">
                        <div className="header-top-left">
                            <span className="email"><i><FaRegEnvelope/></i> <a href="mailto:reservas@alojamentobatata.com">reservas@alojamentobatata.com</a></span>
                            <span className="divisor"><h4>|</h4></span>
                            <span className="phone"><h6><i></i>  +351 919 245 867</h6></span>
                        </div>
                        <div className="header-top-left2">
                            <span className="email"><i><FaRegEnvelope/></i> <a href="mailto:reservas@alojamentobatata.com">reservas@alojamentobatata.com</a></span>
                        </div>
                        
                        
                        <div className="header-top-right">
                        <MyContext.Consumer>
                            {context => (
                                   <span style={{marginRight:"0px",paddingTop:"0px"}}><h5 style={{fontSize:"15px",textTransform:"uppercase"}}>{context.lang}</h5></span>
                                )}
                        </MyContext.Consumer> 
                        <MyContext.Consumer>
                            {context => (
                                    <span style={{marginRight:"0px"}}>
                                    <NavDropdown id="basic-nav-dropdown">
                                                <NavDropdown.Item onClick={() => context.changeLang('pt')}  >PT</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => context.changeLang('fr')}  >FR</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => context.changeLang('en')}  >EN</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => context.changeLang('es')}  >ES</NavDropdown.Item>
                                    </NavDropdown>
                                    </span>
                                )}
                            </MyContext.Consumer> 
                                    <span className="divisor"><h4>|</h4></span>
                                    <span><a href="skype:aurelio.cavaleiro?call"><FaSkype/></a></span>
                                    <span className="divisor"><h4>|</h4></span>
                                    <span><a href="https://www.facebook.com/alojamentobatata"><FaFacebookF/></a></span>
                                    <span className="divisor"><h4>|</h4></span>
                                    <span><a href="https://www.linkedin.com/in/alojamento-batata-868a48144/"><FaLinkedinIn/></a></span>   
                                    <span className="divisor"><h4>|</h4></span>
                                    <span><button onClick={ this.toggleMap} href=""><FaMapMarkerAlt/></button></span>         
                        </div>
                        <div className="header-top-rightLANG">
                        <MyContext.Consumer>
                            {context => (
                                   <span style={{marginRight:"0px",paddingTop:"11px"}}><h5 style={{fontSize:"15px",textTransform:"uppercase",color:"#FFF"}}>{context.lang}</h5></span>
                                )}
                        </MyContext.Consumer> 
                        <MyContext.Consumer>
                            {context => (
                                    <span style={{marginRight:"0px"}}>
                                    <NavDropdown id="basic-nav-dropdown">
                                                <NavDropdown.Item onClick={() => context.changeLang('pt')}  >PT</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => context.changeLang('fr')}  >FR</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => context.changeLang('en')}  >EN</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => context.changeLang('es')}  >ES</NavDropdown.Item>
                                    </NavDropdown>
                                    </span>
                                )}
                            </MyContext.Consumer> 
                        </div>

                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container-header">
                        <nav>
                            <div className="nav-left">
                                <Link to="/"><img src={logo} alt="img1"></img></Link>
                            </div>

                            <div className="nav-right">
                                <ul>

                                    <li><NavLink to="/alojamentos"><Translator name="liAlojamento" /></NavLink></li>
                                    <li><NavLink to="/apartamentos"><Translator name="liApartamentos" /></NavLink></li>
                                    <li><NavLink to="/ofertas"><Translator name="liOfertas" /></NavLink></li>
                                    <li><NavLink to="/nazare"><Translator name="liNazaré" /></NavLink></li>
                                    <li><NavLink to="/contactos"><Translator name="liContactos" /></NavLink></li>
                                    <Link to='/reservar'> <button className="navbarbtn"><Translator name="btnReservarNav" /></button> </Link>
                                </ul>
                            </div>
                            
                            <div className="nav-righBTN">
                            <button class="btn btn-primary" type="button" onClick={this.toggleLinks} style={{color:"#FFF"}}>
                               <FaAlignRight/>
                            </button>
                            </div>
                        </nav>     
                    </div>
                </div>
            </header>
                 <div className="navbarcollapse" style={ this.state.open?{height:'300px',transition:'.25s',textAlign:'left'}: {height:'0',transition:'.5s'} }>
                    { this.state.open === true &&  <><li id="alojamentos" onClick={this.toggleLinks}><Link to='/alojamentos' ><Translator name="liAlojamento" /></Link></li>
                    <li id="apartamentos" onClick={this.toggleLinks}> <Link to='/apartamentos'><Translator name="liApartamentos" /></Link></li>
                    <li id="ofertas" onClick={this.toggleLinks}><Link to='/ofertas'><Translator name="liOfertas" /></Link></li>
                    <li id="nazare" onClick={this.toggleLinks}>< Link to='/nazare'><Translator name="liNazaré" /></Link></li>
                    <li id="contactos" onClick={this.toggleLinks}><Link to='/contactos'><Translator name="liContactos" /></Link></li></>}
                    
                    
                </div>
            </>
        
        )
    }
}
