import React, { Component } from 'react'
import OfertaContainer from '../components/OfertaContainer'
import Slider from '../components/SliderOfertas'
const $ = window.$;

class Ofertas extends Component {
    componentDidMount() {
        document.title = 'Ofertas';
        $('#mapTop').height("0");
      }
    render() {
        return (
            <>
            <Slider/>
            <OfertaContainer />
            </>
        )
    }
}
export default Ofertas

