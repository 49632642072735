import React, { Component } from 'react'
import Client from '../Contentful'
import Titulo from '../components/Titulo'
import Translator from '../components/Translator';



export default class ServicosNazare extends Component {
    state={
        Services: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "nazareServicos"
            });
            let Services = this.formatData(response.items);
            this.setState({
                Services,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let Services = {...item.fields}
            return Services;
            });
        return tempItems2;
    }

    render(){

        const VisitarTit = <Translator name="nazareVisitarTitulo"/>;
        const VisitarSub = <Translator name="nazareVisitarSubtitulo"/>;
        return(
            <>
            <section className="servicesN">
                <div className="services-title">
                <Titulo title={VisitarTit} subtitle={VisitarSub}/>
                </div>
                <div className="services-center">
                    {this.state.Services.map((item,index)=>{
                        return <article key={index} className="service">
                            <div className="leftS">
                            <img src={item.imgServico.fields.file.url} alt="img1"></img>
                            </div>
                            <div className="rightSN">
                            { localStorage.lang === 'en' && <h6> {item.multiTexto.en.nome} </h6>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h6> {item.multiTexto.pt.nome} </h6>}
                            { localStorage.lang === 'es' && <h6> {item.multiTexto.es.nome} </h6>}
                            { localStorage.lang === 'fr' && <h6> {item.multiTexto.fr.nome} </h6>} 
                            </div>
                            </article>
                    })}
                </div>
            </section>
            </>
        )
    }
}