import React from 'react'
import Oferta from './Oferta'
import Translator from '../components/Translator';

export default function OfertasList({Ofertas}) {
    return (
        <div className="container">
            <div className="row" style={{textAlign: "center"}}>
                <div className="col-xs-12">
                    <div className="page-header" style={{display: "inline-block"}}>
                        <h1><Translator name="ofertaListTitulo"/></h1><p><Translator name="ofertaListSubtitulo"/></p>
                        </div>
                </div>
            </div>
        <section className="roomslist">
        <div className="roomslist-center" style={{gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))"}}>
            {
                Ofertas.map(item =>{
                    return <Oferta key={item.id} Oferta={item}/>
                })
            }
        </div>
        </section>
        </div>
    )
}
