import React from "react";
import Slider from "react-slick";
import Title from './TituloTextos'
import {FaComments} from 'react-icons/fa'
import Client from '../Contentful'
import Translator from '../components/Translator';

export default class SliderR extends React.Component {

    state={
        Comentarios: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "comentarios"
            });
            let Comentarios = this.formatData(response.items);
            this.setState({
                Comentarios,
            });
        } catch (error) {
            console.log(error);
            
        }
    }
    
    componentDidMount(){
        this.getData();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let Comentarios = {...item.fields}
            return Comentarios;
            });
        return tempItems2;
    }
    render(){
        
        const titulo=<Translator name="homeComentariosContainerTitulo"/>
        return(
            <div className="blockS">
                <div className="featured-quotes">
                    <Title title={titulo}/>
                    <div className="wrapperS">
                        <Slider
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            infinite={false}
                            dots={true}
                        >
                        {this.state.Comentarios.map((item,index)=>{
                            return <div className="PageS" key={index}>
                            <i><FaComments/></i>
                            { localStorage.lang === 'en' && <h5> {item.multiTexto.en.comentario} </h5>}
                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h5> {item.multiTexto.pt.comentario} </h5>}
                            { localStorage.lang === 'es' && <h5> {item.multiTexto.es.comentario} </h5>}
                            { localStorage.lang === 'fr' && <h5> {item.multiTexto.fr.comentario} </h5>} 
                            <p>{item.site}</p>
                        </div>
                        })}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}