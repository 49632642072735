import React from 'react';
import MyContext from '../context/contextlang';

//using localStorage for storing language on user machine
export default class MyProvider extends React.Component {
    constructor(props){
        super(props);
        this.state = {lang:localStorage.lang ? localStorage.lang:'pt'};
    }
    render() {
        return (
            <MyContext.Provider
                value={{
                    lang:this.state.lang,
                    changeLang: lang => {
                        localStorage.lang = lang;
                        this.setState({
                            lang:lang
                        });
                {window.location.reload(false)}
                    }
                }}
            >
                {this.props.children}
            </MyContext.Provider>
            
        );
    }
}