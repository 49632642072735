import React from 'react'
import PropTypes from 'prop-types'
import Translator from '../components/Translator';

export default function ApartamentoV2( { Apartamento } ) {
    const{nome, slug, preco,imgPrincipal} = Apartamento;
    return (
        <article className="roomv2" onClick={() =>{window.location = `/apartamentos/${slug}`}}>
                <div className="img-container">
                    <img src={imgPrincipal.fields.file.url} alt="single room" />
                </div>
                <div className="room-info">
                    <div className="room-left">
                        <h2 style={{marginTop:"0px",fontSize: "15px",fontWeight: "bold"}}>{nome}</h2>
                        </div>
                    <div className="room-right">
                <div className="room-price">
                    {preco}€
                </div>
                <p><Translator name="homeApartamentosContainerPreço"/></p>
                </div>
                </div>
        </article>
    );
}

ApartamentoV2.propTypes = {
    Apartamento:PropTypes.shape({
        nome:PropTypes.string.isRequired,
        slug:PropTypes.string.isRequired,
        preco:PropTypes.number.isRequired
    })
}
