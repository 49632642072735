import React from 'react'
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps'
import icon from '../images/marker.png'



function Map(){
    return (
    
    <GoogleMap defaultZoom={16} defaultCenter={{lat:39.597240, lng:-9.072190}} >
        
            <Marker position={{lat:39.597240, lng:-9.072190}}
                    icon= {icon}/>
        
    </GoogleMap>
    
    )
}

const WrappedMap = withScriptjs(withGoogleMap( Map ));

export default function googleMaps(){
    return (
        <WrappedMap 
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBv3XmpVxVovvU30smhVOnqraHS1ZIIwS0`}
            loadingElement= {<div style={{ height:"100%"}} />}
            containerElement= {<div style={{ height:"100%"}} />}
            mapElement= {<div style={{ height:"100%"}} />}
            />
           
    )
}