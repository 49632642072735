import React from 'react'
import Hero from '../components/Hero'
import Banner from '../components/BannerError'
import {Link} from 'react-router-dom'

export default function Error() {
    return (
        <div>
            <Hero>
            <Banner title="ERRO 404" subtitle="Não Há Nenhuma Pagina Correspondente A Sua Pesquisa">
                <Link to='/' className="btn-primary">
                    Regressar A Home Page
                </Link>
            </Banner>
            </Hero>
        </div>
    )
}
