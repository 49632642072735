import React from "react"
import ListaQuartos from './QuartosList'
import { withQuartosConsumer } from '../context'
import Loading from './Loading'

function QuartosContainer({context}){
    const {loading, Quartos} = context;
    if(loading){
        return <Loading/>
    }
    return (
        <>
            <ListaQuartos Quartos={Quartos}/>
        </>
    )
}

export default withQuartosConsumer(QuartosContainer)
