import React from "react"
import ListaApartamentos from './ApartamentosList'
import { withApartamentosConsumer } from '../context'
import Loading from './Loading'

function ApartamentosContainer({context}){
    const {loading, Apartamentos} = context;
    if(loading){
        return <Loading/>
    }
    return (
        <>
            <ListaApartamentos Apartamentos={Apartamentos}/>
        </>
    )
}

export default withApartamentosConsumer(ApartamentosContainer)
