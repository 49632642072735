import React, { Component } from 'react'
import Client from '../Contentful'
const $ = window.$;

export default class SliderShow extends Component {

    state={
        Slider: [],
    };
    
    getData = async () =>{
        try {
            let response = await Client.getEntries({
                content_type: "sliderShowOfertas"
            });
            let Slider = this.formatData(response.items);
            this.setState({
                Slider,
            });
        } catch (error) {
            console.log(error);
            
        }
    }

    carouselLoad(){
        $(function(){
            // carousel demo
            $('#myCarousel').carousel()
          })
    }

    componentDidMount(){
        this.getData();
        this.carouselLoad();
        };
    
    formatData(items){
        let tempItems2 = items.map(item =>{
            let Slider = {...item.fields}
            return Slider;
            });
        return tempItems2;
    }

    
    render() {
        
        const {Slider} = this.state;
        const img1 = Slider.map((item,index)=>{
            return item.imagem1.fields.file.url
        })
        const img2 = Slider.map((item,index)=>{
            return item.imagem2.fields.file.url
        })
        const img3 = Slider.map((item,index)=>{
            return item.imagem3.fields.file.url
        })



        return(
            <div class="container-fluid">
            <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="3000" data-pause="false">

                <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
                </ol>

                <div class="carousel-inner" style={{maxHeight:"500px"}}>
                <div class="carousel-item active">
                    <img src={img1} alt="IMG1" style={{width: "100%"}}/>
                    <div class="carousel-caption">
                    { localStorage.lang === 'en' && <h5> {Slider.map(slide => slide.multiTexto.titulo.en)} </h5>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h5> {Slider.map(slide => slide.multiTexto.titulo.pt)} </h5>}
                    { localStorage.lang === 'es' && <h5> {Slider.map(slide => slide.multiTexto.titulo.es)} </h5>}
                    { localStorage.lang === 'fr' && <h5> {Slider.map(slide => slide.multiTexto.titulo.fr)} </h5>} 

                    { localStorage.lang === 'en' && <p> {Slider.map(slide => slide.multiTexto.texto.en)} </p>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {Slider.map(slide => slide.multiTexto.texto.pt)} </p>}
                    { localStorage.lang === 'es' && <p> {Slider.map(slide => slide.multiTexto.texto.es)} </p>}
                    { localStorage.lang === 'fr' && <p> {Slider.map(slide => slide.multiTexto.texto.fr)} </p>} 
                    </div>
                </div>

                <div class="carousel-item">
                    <img src={img2} alt="IMG2" style={{width: "100%"}}/>
                    <div class="carousel-caption">
                    { localStorage.lang === 'en' && <h5> {Slider.map(slide => slide.multiTexto2.titulo.en)} </h5>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h5> {Slider.map(slide => slide.multiTexto2.titulo.pt)} </h5>}
                    { localStorage.lang === 'es' && <h5> {Slider.map(slide => slide.multiTexto2.titulo.es)} </h5>}
                    { localStorage.lang === 'fr' && <h5> {Slider.map(slide => slide.multiTexto2.titulo.fr)} </h5>} 

                    { localStorage.lang === 'en' && <p> {Slider.map(slide => slide.multiTexto2.texto.en)} </p>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {Slider.map(slide => slide.multiTexto2.texto.pt)} </p>}
                    { localStorage.lang === 'es' && <p> {Slider.map(slide => slide.multiTexto2.texto.es)} </p>}
                    { localStorage.lang === 'fr' && <p> {Slider.map(slide => slide.multiTexto2.texto.fr)} </p>} 
                    </div>
                </div>
                
                <div class="carousel-item">
                    <img src={img3} alt="IMG3" style={{width: "100%"}}/>
                    <div class="carousel-caption">
                    { localStorage.lang === 'en' && <h5> {Slider.map(slide => slide.multiTexto3.titulo.en)} </h5>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <h5> {Slider.map(slide => slide.multiTexto3.titulo.pt)} </h5>}
                    { localStorage.lang === 'es' && <h5> {Slider.map(slide => slide.multiTexto3.titulo.es)} </h5>}
                    { localStorage.lang === 'fr' && <h5> {Slider.map(slide => slide.multiTexto3.titulo.fr)} </h5>} 

                    { localStorage.lang === 'en' && <p> {Slider.map(slide => slide.multiTexto3.texto.en)} </p>}
                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <p> {Slider.map(slide => slide.multiTexto3.texto.pt)} </p>}
                    { localStorage.lang === 'es' && <p> {Slider.map(slide => slide.multiTexto3.texto.es)} </p>}
                    { localStorage.lang === 'fr' && <p> {Slider.map(slide => slide.multiTexto3.texto.fr)} </p>} 
                    </div>
                </div> 
                </div>
            </div>
            </div>
        )}
    
}