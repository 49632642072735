import React, { Component } from 'react'
import {OfertasContext} from '../context'
import Ofertas1 from './OfertaV2'
import Title from './TituloCentro'
import Loading from './Loading'
import Slider from "react-slick";
import Translator from '../components/Translator';


export default class OfertasPP extends Component {
    static contextType = OfertasContext;
    render() {
        let{ loading, OfertasPP: Ofertas } = this.context;

        Ofertas = Ofertas.map( Oferta =>{
            return <Ofertas1 key={Oferta.id} Oferta={Oferta}/>
        })
            var settings = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 4,
                initialSlide: 0,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 780,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
              };
            
              const titulo = <Translator name="homeOfertasContainerTitulo"/>;
            return ( 
                <section className="featured-rooms" style={{height:"auto"}}>
                <Title title={titulo}/>
                <div className="wrapperS">
                    <Slider {...settings}
                      
                    >
    
                        {loading ? <Loading/> : Ofertas}
                    </Slider>
                </div>
                </section>
        )
    }
}