import React, { Component } from 'react'
import ApartamentoContainer from '../components/ApartamentoContainer'
import QuartoContainer from '../components/QuartoContainer'
import PaginaApartamentos from '../components/PaginaApartamentos'
const $ = window.$;

class Apartamentos extends Component {
    componentDidMount() {
        document.title = 'Apartamentos';
        $('#mapTop').height("0");
      }
    render() {
        return (
            <>
            <PaginaApartamentos/>
            <ApartamentoContainer />
            <QuartoContainer />
            </>
        )
    }
}

export default Apartamentos;
