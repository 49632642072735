import React from 'react'
import { FaCalendarAlt } from "react-icons/fa";
import Client from '../Contentful'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Translator from '../components/Translator';
import emailjs from 'emailjs-com';
import config from '../Email';

import moment from 'moment';
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pt";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/es";

const $ = window.$;

class Formulario2 extends React.Component {
    
        state={
            AHero: [],
            AHero2: [],
            AHero3: [],
            send: false,
            locale: localStorage.lang,
            localeDay: undefined,
        };

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(config.SERVICE_ID, config.TEMPLATE_ID, e.target, config.USER_ID)
            .then((result) => {
                document.getElementById("mailForm").reset();
                this.state = { send : true}
                $('#modalFormulario').modal('toggle');
            }, (error) => {
                console.log(error.text);
            });
    }
    
    handleClick = () =>{
        this.state = ({ send : false})
        document.getElementById("oferta2").style.visibility = "hidden";
    }

    getData = async () =>{
        try {

            let response = await Client.getEntries({
                content_type: "apartamento"
            });

            let response2 = await Client.getEntries({
                content_type: "quarto"
            });

            let response3 = await Client.getEntries({
                content_type: "oferta"
            });

            
            let AHero = response.items.map(item =>{
            let AHero = {...item.fields}
            return AHero;
            });

            let AHero2 = response2.items.map(item =>{
                let AHero2 = {...item.fields}
                return AHero2;
            });

            
            let AHero3 = response3.items.map(item =>{
                let AHero3 = {...item.fields}
                return AHero3;
            });
           
           

            this.setState({
                AHero,
                AHero2,
                AHero3,
            });

        } catch (error) {
            console.log(error);
            
        }
    }
            
            componentDidMount(){
                  this.getData ();

                  if(localStorage.lang === undefined){
                    this.setState({
                    checkin2: moment(new Date()).locale("pt").format("DD/MMMM"),
                    checkout2: moment(new Date()).locale("pt").add(1, 'day').format("DD/MMMM"),
                    localeDay: "pt"
                    })
                }else{
                    this.setState({
                        checkin2: moment(new Date()).locale(this.state.locale).format("DD/MMMM"),
                        checkout2: moment(new Date()).locale(this.state.locale).add(1, 'day').format("DD/MMMM"),
                        localeDay: this.state.locale
                        })
                }
            }

          

        render() {

            function changeOferta(){
                var dis1 = document.getElementById("oferta");
                    if (dis1 !== "oferta" || this.value.length > 0) {
                        document.getElementById("oferta2").style.visibility = "visible";
                    }else{
                        document.getElementById("oferta2").style.visibility = "hidden";
                    }
            }     
            
            
        return (
            <>
            <div className="block-reservar-formulario">
                <form onSubmit={this.sendEmail} id="mailForm">
                    <div className="booking-form">
                    <div className="container">
                        <div className="row">
                                <div className="col-xs-12 col-md-5">
                                    <div className="booking-form-details">
                                        <div className="page-header">
                                            <h1 style={{color:"#FFF"}}><Translator name="FormTitleV1"/></h1>
                                        </div>
                                            <div className="form-options">
                                                <div className="form-group">
                                                    <label className="control-label" style={{fontSize:"20px", fontWeight:"normal"}}><Translator name="FormApartamentoTitle"/></label>
                                                    <div className="input-group select-wrapper">
                                                    <select className="form-control" name="apartamento" id="apartamento"style={{backgroundColor:"#2dc3fc",color:"#FFF",border: "1px solid #FFF"}}  defaultValue={1} required>
                                                    { localStorage.lang === 'en' && <option hidden disabled value={1}>Choose an offer</option>}
                                                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <option hidden disabled value={1}>Escolha uma opcao</option>}
                                                    { localStorage.lang === 'es' && <option hidden disabled value={1}>Elija una oferta</option>}
                                                    { localStorage.lang === 'fr' && <option hidden disabled value={1}>Choisissez une offre</option>} 

                                                    {this.state.AHero.map((item,index)=>{
                                                            return <option key={index}>{item.nome}</option>   
                                                    })}
                                                    {this.state.AHero2.map((item,index)=>{
                                                            return <option key={index}>{item.nomeq}</option>   
                                                    })}
                                                    </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="bPo">
                                                        <div className="col-xs-6">
                                                        <label className="control-label " style={{fontSize:"20px", fontWeight:"normal"}}>
                                                                Check in
                                                            </label>
                                                            <div className="input-group mb-3" style={{height:"38px",border: "1px solid #FFF"}}>
                                                            <span className="input-group-prepend" style={{float:"left", width:"20%"}}>
                                                                <span className="input-group-text" style={{border:"none"}}><FaCalendarAlt/>
                                                                </span>
                                                            </span>
                                                            <DayPickerInput dayPickerProps={{
                                                                localeUtils: MomentLocaleUtils,
                                                                locale:this.state.localeDay
                                                            }}
                                                            inputProps={{ name: 'checkin' }} placeholder="" style={{float:"right",width:"80%"}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6">
                                                        <label className="control-label " style={{fontSize:"20px", fontWeight:"normal"}}>
                                                                Check out
                                                            </label>
                                                            <div className="input-group mb-3" style={{height:"38px",border: "1px solid #FFF"}}>
                                                            <span className="input-group-prepend" style={{float:"left", width:"20%"}}>
                                                                <span className="input-group-text" style={{border: "none"}}><FaCalendarAlt/>
                                                                </span>
                                                            </span>
                                                            <DayPickerInput dayPickerProps={{
                                                                localeUtils: MomentLocaleUtils,
                                                                locale:this.state.localeDay
                                                            }}
                                                            inputProps={{ name: 'checkout' }} placeholder="" style={{float:"right",width:"80%"}}/>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="bPo">
                                                        <div className="col-xs-6">
                                                        <label className="control-label" style={{fontSize:"20px", fontWeight:"normal"}}>
                                                        <Translator name="formAdultosTitle"/>
                                                            </label>
                                                            <div className="input-group">
                                                            <select className="form-control" name="adulto" id="adulto" type="text" style={{backgroundColor:"#2dc3fc",color:"#FFF",border: "1px solid #FFF"}} defaultValue={1} required>
                                                            
                                                            { localStorage.lang === 'en' && <option hidden disabled value={1}>Adults</option>}
                                                            { (localStorage.lang === undefined || localStorage.lang === 'pt') && <option hidden disabled value={1}>Adultos</option>}
                                                            { localStorage.lang === 'es' && <option hidden disabled value={1}>Adultos</option>}
                                                            { localStorage.lang === 'fr' && <option hidden disabled value={1}>Adultes</option>} 

                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                            <option>6</option>
                                                            <option>7</option>
                                                            <option>8</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6">
                                                        <label className="control-label " style={{fontSize:"20px", fontWeight:"normal"}}>
                                                        <Translator name="formAdultosTitle"/>
                                                            </label>
                                                            <div className="input-group">
                                                                    <select className="form-control" name="crianca" id="crianca" style={{backgroundColor:"#2dc3fc",color:"#FFF",border: "1px solid #FFF"}} defaultValue={1} required>
                                                                    
                                                                    { localStorage.lang === 'en' && <option hidden disabled value={1}>Children</option>}
                                                                    { (localStorage.lang === undefined || localStorage.lang === 'pt') && <option hidden disabled value={1}>Crianças</option>}
                                                                    { localStorage.lang === 'es' && <option hidden disabled value={1}>Niños</option>}
                                                                    { localStorage.lang === 'fr' && <option hidden disabled value={1}>Enfants</option>} 
                                                                    <option>0</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                    </select>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    
                                                <div className="form-group">
                                                        <label className="control-label" style={{fontSize:"20px", fontWeight:"normal"}}><Translator name="formOfertaTitle"/></label>
                                                        <div className="input-group">
                                                        <select className="form-control" name="slug2" id="oferta" type="text" style={{backgroundColor:"#2dc3fc",color:"#FFF",width: "100%",marginBottom:"10px",border: "1px solid #FFF"}} defaultValue={1} onChange={changeOferta} required>
                                                            
                                                        { localStorage.lang === 'en' && <option hidden disabled value={1}>Choose an offer</option>}
                                                        { (localStorage.lang === undefined || localStorage.lang === 'pt') && <option hidden disabled value={1}>Escolha uma opcao</option>}
                                                        { localStorage.lang === 'es' && <option hidden disabled value={1}>Elija una oferta</option>}
                                                        { localStorage.lang === 'fr' && <option hidden disabled value={1}>Choisissez une offre</option>} 

                                                            {localStorage.lang === 'en' && <option> Accommodation </option>}
                                                            {localStorage.lang === 'pt' && <option> Alojamento </option>}
                                                            {localStorage.lang === 'es' && <option> Alojamiento </option>}
                                                            {localStorage.lang === 'fr' && <option> Hébergement </option>}
                                                            {this.state.AHero3.map((item,index)=>{
                                                            return <option key={index}>{item.nomeoferta}</option>
                                                             })}
                                                        </select>
                                                        <select name="slug3" id="oferta2" className="form-control" style={{backgroundColor:"#2dc3fc",color:"#FFF",width: "100%",border: "1px solid #FFF"}} defaultValue={1}>
                                                            
                                                        { localStorage.lang === 'en' && <option hidden disabled value={1}>Choose an offer</option>}
                                                        { (localStorage.lang === undefined || localStorage.lang === 'pt') && <option hidden disabled value={1}>Escolha uma opcao</option>}
                                                        { localStorage.lang === 'es' && <option hidden disabled value={1}>Elija una oferta</option>}
                                                        { localStorage.lang === 'fr' && <option hidden disabled value={1}>Choisissez une offre</option>} 

                                                            <option>Oferta A</option>
                                                            <option>Oferta B</option>
                                                         </select>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-offset-1 col-md-6">
                                        <div className="booking-form-personal">
                                        <div className="page-header">
                                            <h1><Translator name="FormTitleV2"/></h1>
                                            <p><Translator name="FormSubtitle"/></p>
                                        </div>
                                        <div className="form-horizontal">
                                            <div className="form-group" style={{display:"flex"}}>
                                            <label className="col-xs-2 control-label labelPeq" style={{fontSize:"14px"}}><Translator name="formNomePlaceholder"/></label>
                                                <div className="col-xs-10">
                                                    <input type="text" name="nome" className="form-control" required/>
                                                </div>
                                            </div>
                                        <div className="form-group" style={{display:"flex"}}>
                                        <label className="col-xs-2 control-label labelPeq" style={{fontSize:"14px"}}><Translator name="formEmailPlaceholder"/></label>
                                            <div className="col-xs-10">
                                                <input type="email" name="email" className="form-control" required/>
                                            </div>
                                        </div>
                                        <div className="form-group" style={{display:"flex"}}>
                                        <label className="col-xs-2 control-label labelPeq" style={{fontSize:"14px"}}><Translator name="formTelefonePlaceholder"/></label>
                                            <div className="col-xs-10">
                                                <input type="text" name="telefone" className="form-control" required/>
                                            </div>
                                        </div>
                                        <div className="form-group" style={{display:"flex"}}>
                                        <label className="col-xs-2 control-label labelPeq" style={{fontSize:"14px"}}><Translator name="formMensagemPlaceholder"/></label>
                                            <div className="col-xs-10">
                                                <textarea name="mensagem" rows="4" className="form-control" required></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group" style={{display:"flex"}}>
                                        <label className="col-xs-2 control-label labelPeq"></label>
                                            <div className="col-xs-10 col-xs-offset-2">
                                                <button type="submit" value="Send" className="btn btn-lg btn-primary1 btn-block" onClick={this.handleClick}><Translator name="formRSVPlaceholder"/></button>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="modalFormulario" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{height: "auto"}}>
                                            <div class="modal-dialog cascading-modal" role="document" style={{height: "auto"}}>
                                                <div class="modal-content" style={{height: "auto"}}>   
                                                    <div class="modal-header light-blue darken-3 white-text" style={{height: "auto"}} >
                                                        <h4 class="title"><i class="fas fa-users"></i></h4>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>                 
                                                    <div class="modal-body mb-0 text-center" style={{height: "auto"}}>                           
                                                        <h2 style={{color:"black",fontSize:"15px",paddingTop:"0px",paddingLeft:"0px"}}><Translator name="modalFormSubmitFormulario"/></h2>
                                                    </div>
                                                </div>
                                            </div>
                        </div>
                </form>
            </div>
        </>
        )
    }
}

export default Formulario2;